import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {useContext, useEffect, useState} from "react";
import {getUpdateMarketplace, marketplaceContent} from "../../http/userAPI";
import NotifyING from '../../assets/wildberries.jpg'
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const AlignItemsList = observer(() => {
    const {user} = useContext(Context)
    const [data,setData] = useState([])

    useEffect(()=>{
            getUpdateMarketplace().then(data => setData(data))
            console.log(data)
    },[user.add])

    useEffect(()=>{

        console.log(data)
    },[data])

    return (
        <>
            <div className={'notif'} style={{width:'100%',height:'40px',background:'white',marginLeft:'14px',marginTop:'4px',borderBottom:'1px solid black'}}>
                <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="subtitle1"
                    color="text.primary"
                >
                    Уведомления</Typography></div>
        <List sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'white',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
        }}>
            {data?.map((listItem,index)=>(
                <>
            <ListItem alignItems="flex-start">
                <ListItemAvatar sx={{objectFit:'fill'}}>
                    <Avatar alt="Markteplace"
                            sx={{".css-1pqm26d-MuiAvatar-img":{
                                objectFit:'fill'
                            }}}
                            src={require(`../../assets/${listItem.marketName.toLowerCase()}.jpg`)}
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={"Системное уведомление " + listItem.marketName}
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {listItem.name}
                            </Typography>
                            {" — " + listItem.type + " " + listItem.date}

                        </React.Fragment>
                    }
                />
            </ListItem>
                <Divider variant="inset" component="li" />
            </>
            ))}
        </List>
        </>
    );
})

export default AlignItemsList