import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {observer} from "mobx-react-lite";
import {useContext, useEffect, useState} from "react";
import {allMarketplace} from "../http/userAPI";
import {Context} from "../index";
import TextField from '@mui/material/TextField';
const ScrollableTabsButtonVisible = observer(() => {

    const [marketplaces, setMarketplaces] = useState({data:[]})
    const {user} = useContext(Context)

    useEffect(()=>{
        allMarketplace(user.storeId.id.toString()).then(data => setMarketplaces(data))
    },[])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        user.setscrollCheck(newValue)
    };

    const handleClick = (event) => {
        user.setMarketplaceCheck(event.target.id)
    }

    return (
        <Box
            sx={{
                flexGrow: 1,
                width: '100%',
                
                bgcolor: 'background.paper',
            }}
        >	

            <Tabs
                value={user.scrollCheck}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                aria-label="visible arrows tabs example"
                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                }}
            >
                {marketplaces.data?.map((market,)=>(
                    <Tab id={market.id} label={market.marketplaceType} onClick={handleClick}/>
                ))}
            </Tabs>
        </Box>
    );
})

export default ScrollableTabsButtonVisible