import React, {useContext, useEffect} from 'react';
import "../style/App.css";
import SideMenu from "./SideMenu";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { useState } from "react";
import {authRoutes, publicRoutes} from "../routes";
import PublicPage from "../pages/PublicPage";
import Dashboard from "../pages/Dashboard";
import {Context} from "../index";
import ResponsiveAppBar from "./ResponsiveAppBar";
import {observer} from "mobx-react-lite";
import CustomizedSnackbars from "./BasicSnapbar";
import Auth from "../pages/Auth";
import socket from '../network/socket'
import {check, getEmail} from "../http/userAPI";
import {LOGIN_ROUTE} from "../utils/const";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import ColorToggleButton from "./ToggleButton";
import TextField from "@mui/material/TextField";
import {addStore} from "../http/storeAPI";
import { Navigate } from "react-router-dom";

const AlertDialog2 = observer(() => {
    const {user} = React.useContext(Context)

    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState('')
    const [load,setLoad] = React.useState(true)

    useEffect(()=>{
      if(user.storeId.name === '' || user.stateStoreDialog){
          setOpen(true)
      }else{setOpen(false)}
    },[user.storeId.name,user.stateStoreDialog])

    const handleAdd = async () => {
        await addStore(text).then()
        setOpen(false);
        user.setAddStore(true)
        user.setSnapbar(true,'success','Магазин успешно добавлен!')
        user.setAddStore(false)
    };

    const handleClose = () => {
        setOpen(false);
        user.setStateStoreDialog(false)
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"С каким магазином работаем?"}
                </DialogTitle>
                <DialogContent>
                    <ColorToggleButton/>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Добавить магазин"
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={(e) =>
                            setText(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button onClick={handleAdd} autoFocus>
                        Добавить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
})

const AppRouter = observer(() => {
    const [inactive, setInactive] = useState(false);
    const {user} = useContext(Context)


    console.log(user.isAuth)
    console.log(user.inactive)
    return (
        <div className="App">
            <CustomizedSnackbars/>
            {user.isAuth ? (<AlertDialog2/>):null}
            <Router>
                {user.isAuth ? (
                <SideMenu
                    onCollapse={(inactive) => {
                        user.setInactive(inactive);
                        console.log(inactive);
                        setInactive(inactive);
                    }}
                /> ) : false }

                {user.isAuth ? (<ResponsiveAppBar/>): false }

                {user.isAuth ? (
                        <div className={` ${inactive ? "container inactive" : "container"}`}>
                            <Routes>
                                {authRoutes.map((menu, index) => (
                                    <>
                                        <Route key={menu.name} path={menu.path} element={menu.Component} exact/>
                                        {menu.subMenus && menu.subMenus.length > 0
                                            ? menu.subMenus.map((subMenu, i) => (
                                                <Route key={subMenu.name} path={subMenu.path} element={subMenu.Component}
                                                       exact/>
                                            ))
                                            : <Route path={"*"} element={<Dashboard/>} exact/>}
                                    </>
                                ))}
                            </Routes>
                        </div>) :

                    <Routes>
                        {publicRoutes.map((menu, index) => (
                            <>
                                <Route key={menu.name} path={menu.path} element={menu.Component} exact/>
                                {menu.subMenus && menu.subMenus.length > 0
                                    ? menu.subMenus.map((subMenu, i) => (
                                        <Route key={subMenu.name} path={subMenu.path} element={subMenu.Component}
                                               exact/>
                                    ))
                                    : <Route path={"*"} element={<Navigate to='/login' />} exact/>}
                            </>
                        ))}
                    </Routes>
                }
            </Router>
        </div>
    );
})

export default AppRouter;