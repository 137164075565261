import React, {useEffect} from 'react';

import {observer} from "mobx-react-lite";
import { Line } from "react-chartjs-2";
import {Context} from "../../../index";
import {getAllLineChart} from "../../../http/marketAPI";
import { toJS } from 'mobx'
import {getAllCompareContent} from "../../../http/storeAPI";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const options = {
  responsive: true,
  maintainAspectRatio: false, 
  borderWidth:1,
  scales: {
  x: {
    grid: {
      tickLength: 1,

    },

  },
  y: {
    grid: {
      tickLength: 1,
      borderDash:[1,45],
    },
  },
  	
},
};


const LineChart2 = observer(({labels2,dataVal, fullSumm, fullVal, type}) => {
    const data2 = [
      {
      label: 'Ozon',
      data: [{x:'01-02', Ozon:'2222'}],
      parsing: {
        yAxisKey: 'Ozon'
      },
      backgroundColor: 'blue', 
      borderColor:'blue'
      },
  ];

  const [res,setRez] = React.useState({labels:['01-02'], dataset: [
      {
      label: 'Ozon',
      data: [{}],
      parsing: {
        yAxisKey: 'Ozon'
      },
      backgroundColor: 'blue', 
      borderColor:'blue'
      },
  ]})

  const {analytics,user} = React.useContext(Context)

  useEffect(()=>{
        
        
       getAllCompareContent(user.storeId.id.toString(), analytics.compareInterval).then(data=> setRez(data)) 
     
    },[analytics.compareTrigger])

  useEffect(()=>{
  	console.log(res)
  },[res])

  const data = {
    labels: res['labels'],
    datasets: res['dataset']
  };


  return (

    <div className="CartLine" style={{position: 'relative', margin: 'auto', height: '100%', minWidth: '100%'}}>
    
      <Line options={options} data={data} />

    </div>
  );
});

export default LineChart2;