import React from 'react';
import {TextField} from "@mui/material";
import Box from "@mui/material/Box";
import user from "../assets/user.jpg";
import Button from "@mui/material/Button";
import "../style/Price.css";
import Typography from "@mui/material/Typography";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {changePassword,getPreference,updatePreference} from "../http/userAPI";
import {useEffect} from "react";
import DriwerMenuPreference from "../components/DriwerMenuPreference";

const Settings = observer(() => {
    const {user} = React.useContext(Context)
    const [pass,setPass] = React.useState('');
    const [rePass,setRePass] = React.useState('');
    const [rePass2,setRePass2] = React.useState('');
    const [error,setError] = React.useState(false);
    const [error2,setError2] = React.useState(false);
    const [error3,setError3] = React.useState(false);
    const premium = "rgb(38 0 251 / 60%) 0px 8px 12px 0px";
    const standart = "rgb(0 77 251 / 60%) 0px 8px 12px 0px";
    const probnaya = "rgb(0 0 0 / 60%) 0px 8px 12px 0px";
    const minimum = "rgb(0 228 251 / 60%) 0px 8px 12px 0px";
    
    const [rezultColor, setRezultColor] = React.useState("");
    const [rezultColorMin, setRezultColorMin] = React.useState("");
    const [rezultColorStand, setRezultColorStand] = React.useState("");
    const [rezultColorProb, setRezultColorProb] = React.useState("");
    const [pref,setPref] = React.useState("");

    useEffect(()=>{
        getPreference().then(data => {
                if(data === "Премиум"){
                	setRezultColor(premium)
                    setRezultColorMin("")
                    setRezultColorStand("")
                    setRezultColorProb("")
		        }
                if(data === "Минимальная"){
                    setRezultColorMin(minimum)
                    setRezultColorStand("")
                    setRezultColorProb("")
                    setRezultColor("")
                }
                if(data === "Экстра"){
                    setRezultColorStand(standart)
                    setRezultColorMin("")
                    setRezultColorProb("")
                    setRezultColor("")
                }
                if(data === "Пробная"){
                    setRezultColorProb(probnaya)
                    setRezultColorStand("")
                    setRezultColorMin("")
                    setRezultColor("")
                }
        })
    },[pref])

    const handleClickUpdate = async (event) => {
        user.setDriwerPreference(true)
        await updatePreference(event.target.value).then()
        setPref(event.target.value)
    }
    
    const handleClick = async (event) => {
        if (!pass) {
            setError(true)
            return user.setSnapbar(true, 'error', 'Поле не должно быть пустым')
        } else {
            setError(false)
        }
        if (!rePass) {
            setError2(true)
            return user.setSnapbar(true, 'error', 'Поле не должно быть пустым')
        } else {
            setError2(false)
        }
        if (!rePass2) {
            setError3(true)
            return user.setSnapbar(true, 'error', 'Поле не должно быть пустым')
        } else {
            setError3(false)
        }
        if (rePass2 !== rePass) {
            setError3(true)
            setError2(true)
            return user.setSnapbar(true, 'error', 'Новые пароли должны совпадать!')
        } else {
            setError3(false)
            setError2(false)
        }

        try {
            const result = await changePassword(pass, rePass)
            if(result){
                return user.setSnapbar(true, 'success', 'Пароль изменен')
            }
        } catch (e) {
            return user.setSnapbar(true, 'error', `${e.response.data.message}`)
        }


    };

    return (
        <>
        <div className='blockPassword' style={{display:'block'}}>
            
            <div className='blockPassword avatar' style={{height:'100%'}}>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '91%' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Typography variant="h6" >
                        Смена пароля
                    </Typography>
                <TextField onChange={(e) =>
                    setPass(e.target.value)} label="Старый пароль" variant="standard" error={error}/>
                <TextField id="repassword" onChange={(e) =>
                    setRePass(e.target.value)}
                           label="Новый пароль" variant="standard" error={error2}/>
                <TextField id="repassrord2" onChange={(e) =>
                    setRePass2(e.target.value)}
                           label="Новый пароль" variant="standard" error={error3}/>
            </Box>
                <Button sx={{marginBottom:'10px',marginTop:'4px',marginLeft:'1%'}} onClick={handleClick}>Изменить</Button>
            </div>
            <div className="permBlock">
            <div className="columns">
                <ul className="price"  style={{boxShadow: rezultColorProb}}>
                    <li className="header">Пробная</li>
                    <li className="grey">Бесплатно</li>
                    <li>2 свои схемы</li>
                    <li>2 маркетплейса</li>
                    <li className="grey"><Button value="Пробная" onClick={handleClickUpdate}>Обновить</Button></li>
                </ul>
            </div>
                <div className="columns">
                    <ul className="price"  style={{boxShadow: rezultColorMin}}>
                        <li className="header" style={{background:"#5be3f7"}}>Минимальная</li>
                        <li className="grey">1000 ₽ / Месяц</li>
                        <li>1 своя схема</li>
                        <li>1 Маркетплейс</li>
                        <li className="grey"><Button value="Минимальная" onClick={handleClickUpdate}>Обновить</Button></li>
                    </ul>
                </div>
            <div className="columns">
                <ul className="price" style={{boxShadow: rezultColorStand}}>
                    <li className="header" style={{background:"#1466d1"}}>Экстра</li>
                    <li className="grey">4000 ₽ / Месяц</li>
                    <li>4 свои схемы</li>
                    <li>3 Маркетплейса</li>
                    <li className="grey"><Button value="Экстра" onClick={handleClickUpdate}>Обновить</Button></li>
                </ul>
            </div>
            <div className="columns">
                <ul className="price" style={{boxShadow: rezultColor}}>
                    <li className="header" style={{background:"#a519f7"}}>Премиум</li>
                    <li className="grey">7000 ₽ / Месяц</li>
                    <li>Не ограниченно</li>
                    <li>Не ограниченно</li>
                    <li className="grey"><Button value="Премиум" onClick={handleClickUpdate}>Обновить</Button></li>
                </ul>
            </div>

         </div>
        </div>
        
       
        </>
    );
});

export default Settings;
