import io from "socket.io-client";

const Socket = io('https://lk.overplace.ru:7000',{
    withCredentials: true,
    extraHeaders: {
        "my-custom-header": "abcd"
    }
})

export default Socket
