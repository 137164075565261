import React, {useEffect} from 'react';

import {observer} from "mobx-react-lite";
import { Line } from "react-chartjs-2";
import {Context} from "../../../index";
import {getAllLineChart} from "../../../http/marketAPI";
import { toJS } from 'mobx'


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const labels = ["2017", "2018", "2019", "2020", "2021", "2022"];

const options = {
  responsive: true,
  maintainAspectRatio: false, 
  scales: {
  x: {
   
    grid: {
      tickLength: 2,

    },
   stacked: true,
  },
  y: {
    
    grid: {
      tickLength: 1,
      borderDash:[1,45],
    },
    stacked: true,
  },
  	
},
};


const LineChart = observer(({labels2,dataVal, fullSumm, fullVal, type}) => {

  const [res,setRez] = React.useState({summ:[{x:'2022-01-02', YandexDBS:'2222'}], 
    labels: ["2022-01-02"]})

  const {analytics,user} = React.useContext(Context)

  useEffect(()=>{
       getAllLineChart(user.storeId.id.toString(), toJS(analytics.analyticMenuDataMarketplaces), analytics.analyticMenuDataFrom, analytics.analyticMenuDataTo, analytics.analyticMenuDataInterval).then(data => setRez(data))
    },[analytics.analyticMenuDataMarketplaces,analytics.analyticMenuDataInterval])

  useEffect(()=>{
  	console.log(res)
  },[res])

  const data = {
    labels: res['labels'],
    datasets: [{
      label: 'Ozon',
      data: res['summ'],
      parsing: {
        yAxisKey: 'Ozon'
      },
      backgroundColor: 'blue', 
      borderColor:'blue'
    }, {
      label: 'YandexDBS',
      data: res['summ'],
      parsing: {
        yAxisKey: 'YandexDBS'
      },
      backgroundColor: '#dbc558', 
      borderColor: '#dbc558'
    }, {
      label: 'Sbermarket',
      data: res['summ'],
      parsing: {
        yAxisKey: 'Sbermarket'
      },
      backgroundColor: 'green', 
      borderColor: 'green'
    }, {
      label: 'Wildberries',
      data: res['summ'],
      parsing: {
        yAxisKey: 'Wildberries'
      },
      backgroundColor: '#e360fa', 
      borderColor: '#e360fa'
    }]
  };


  return (

    <div className="CartLine" style={{position: 'relative', margin: 'auto', height: '100%', minWidth: '100%'}}>
    
      <Bar options={options} data={data} />

    </div>
  );
});

export default LineChart;