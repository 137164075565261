import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from "@mui/material/Button";
import {useEffect} from "react";
import {getAllStore} from "../http/storeAPI";
import {Context} from "../index";
import {observer} from "mobx-react-lite";

const ColorToggleButton = observer(() => {
    const {user,analytics} = React.useContext(Context)
    const [alignment, setAlignment] = React.useState(user.storeId.id);
    const [stores,setStores] = React.useState([])

    useEffect(()=>{
       getAllStore().then(data => setStores(data))
    },[user.addStore])


    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {

        setAlignment(newAlignment);
        if(newAlignment !== null) {
            user.setStateStoreDialog(false)
            user.setStoreId(newAlignment, event.target.id)
            analytics.setAnalyticMenuDataMarketplaces([])
        }
    };

    return (

        <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
        >
            {stores.map((store) => (
            <ToggleButton value={store.id} id={store.name}>{store.name}</ToggleButton>
                ))}
        </ToggleButtonGroup>

    );
})

export default ColorToggleButton