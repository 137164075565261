import {
    ADMIN_ROUTE,
    ANALYTICS_ROUTE, CALCULATOR_ROUTE,
    DASHBOARD_ROUTE,
    LOGIN_ROUTE, PIM_ROUTE, PIM_SETTINGS, PIM_UNITE,
    PRICES_ROUTE,
    REGISTRATION_ROUTE, SETTINGS_ROUTE,
    SITE_ROUTE
} from "./utils/const";
import Admin from "./pages/Admin";
import Analytics from "./pages/Analytics";
import Dashboard from "./pages/Dashboard";
import Auth from "./pages/Auth";
import PublicPage from "./pages/PublicPage";
import Prices from "./pages/Prices";
import Calculator from "./pages/Calculator";
import Settings from "./pages/Settings";
import PimSettings from "./pages/PimSettings";
import Pim from "./pages/Pim";
import PimUnite from "./pages/PimUnite";


export  const authRoutes = [
    {
        path: DASHBOARD_ROUTE,
        Component: <Dashboard />,
        name: "Сводка",
        iconClassName: "bi bi-ui-checks-grid"
    },
    {
        path: ANALYTICS_ROUTE,
        Component: <Analytics />,
        name: "Аналитика",
        iconClassName: "bi bi-bar-chart-line"
    },
    {
        path: CALCULATOR_ROUTE,
        Component: <Calculator />,
        name: "Схемы",
        iconClassName: "bi bi-plus-square"
    },
    {
        path: PIM_SETTINGS,
        Component: <PimSettings />,
        name: "Цены",
        subMenus: [
            { name: "Изменение цен", path: PIM_SETTINGS , Component: <PimSettings />},
            { name: "Группировка товаров", path: PIM_UNITE , Component: <PimUnite />},
        ],
        iconClassName: "bi bi-menu-button-wide"
    },
    {
        path: SETTINGS_ROUTE,
        Component: <Settings />,
        name: "Настройки",
        iconClassName: "bi bi-sliders"
    },
]

export const publicRoutes = [
   
    {
        path: LOGIN_ROUTE,
        Component: <Auth />,
        name: "LOGIN",
        iconClassName: "bi bi-speedometer2"
    },
    {
        path: REGISTRATION_ROUTE,
        Component: <Auth />,
        name: "REG",
        iconClassName: "bi bi-speedometer2"
    },
    {
        path: PRICES_ROUTE,
        Component: <Prices />,
        name: "Prices",
        iconClassName: "bi bi-vector-pen"
    },
]