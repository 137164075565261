import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {observer} from "mobx-react-lite";
import {useContext, useEffect, useState} from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {allMarketplace} from "../../../http/userAPI";
import {Context} from "../../../index";
import { toJS } from 'mobx'

const Autocomplete2 = observer(() => {

  const [marketplaces, setMarketplaces] = useState({data:[]})
  const [labels,setLabels] = useState([])
  const [labels2,setLabels2] = useState([])

  const {user} = useContext(Context)
  const {analytics} = useContext(Context)

  useEffect(()=>{
        allMarketplace(user.storeId.id.toString()).then(data => {
        const arr = []
        data?.data?.forEach((data2)=>{
          arr.push({marketplaceType:data2.marketplaceType,id:data2.id})
        })
        setLabels(arr)
        user.setMarketplaceCheck(2)
        setLabels2(arr)
        setMarketplaces(data)
        const rez = toJS(analytics.analyticMenuDataMarketplaces)
        if(rez.length < 1){
        analytics.setAnalyticMenuDataMarketplaces(arr)
        }
        })

  },[user.storeId.id])

  useEffect(()=>{
    console.log(toJS(analytics.analyticMenuDataMarketplaces))
    console.log(labels2)
  },[labels])

  const change = async (event,newValue) => {
    analytics.setAnalyticMenuDataMarketplaces(newValue)
    console.log(newValue)
    setLabels(newValue)
  }

  const arr = [];
    return (
      <Box
            sx={{
                flexGrow: 1,
                width: '100%',
                bgcolor: 'background.paper',
            }}
        > 
        <Autocomplete
        multiple
        id="tags-standard"
        options={labels2}
        getOptionLabel={(option) => option.marketplaceType}
        value={toJS(analytics.analyticMenuDataMarketplaces)}
        onChange={(event, newValue) => change(event,newValue)}
        isOptionEqualToValue={(option, value) => option.marketplaceType === value.marketplaceType}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Маркетплейсы"
            placeholder="Маркетплейс"
          />
        )}
      />
      </Box>  
    );
});

export default Autocomplete2