import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {useContext, useEffect, useState} from "react";
import {getUpdateMarketplace, marketplaceContent,getMarketplaceGroupByDate} from "../../../http/userAPI";
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import PropTypes from "prop-types";
import CartInfo from "./CartInfo";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import { toJS } from 'mobx'

const TopBlock = observer(() => {

    const [saleQuantity,setSaleQuantity] = React.useState([])
    const {analytics,user} = React.useContext(Context)

    useEffect(()=>{
      getMarketplaceGroupByDate(user.storeId.id.toString(), toJS(analytics.analyticMenuDataMarketplaces), analytics.analyticMenuDataFrom, analytics.analyticMenuDataTo, analytics.analyticMenuDataInterval).then(data => setSaleQuantity(data))
    },[analytics.analyticMenuDataMarketplaces,analytics.analyticMenuDataInterval,analytics.analyticMenuDataFrom,analytics.analyticMenuDataTo])

    useEffect(()=>{
      console.log(saleQuantity)
    },[saleQuantity])

    const labels2 = saleQuantity?.['labels'];
    const dataVal2 = saleQuantity?.['values'];
    const dataVal3 = saleQuantity?.['summ'];
    const dataVal4 = saleQuantity?.['fullSumm'];
    const dataVal5 = saleQuantity?.['fullvalues'];

    return (
    	<div className="GridTab" style={{display:'flex',flexWrap:'wrap', marginBottom:20,width:'100%',justifyContent:'space-between'}}>

          <div className="CartInfoAnalytic" style={{background:'linear-gradient(to right,#b823f8 0,#36c0e8 100%) no-repeat', overflow:'hidden', borderRadius:'25px', minWidth: '300px', width:'49.0%', marginBottom:10,boxShadow:'0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'}}>
           <CartInfo labels = {labels2} dataVal = {dataVal3} type = {'Выручка'} fullSumm = {dataVal4}/>
          </div>
      
          <div className="CartInfoAnalytic" style={{background:'linear-gradient(to right, rgb(184, 35, 248) 0px, rgb(232, 54, 87) 100%) no-repeat',borderRadius:'25px', overflow:'hidden',minWidth: '300px', width:'49.0%', marginBottom:10,boxShadow:'0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'}}>
           <CartInfo labels = {labels2} dataVal = {dataVal2} type = {'Колличество'} fullSumm = {dataVal4} fullVal = {dataVal5}/>
          </div>

          

        </div>
     
    );
})

export default TopBlock