import React, {useEffect, useState} from 'react';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import BasicEditingGrid from "./TableXMui";
import {getAllProductsPim, updateProductStatusPrice} from "../http/marketAPI";
import SendIcon from '@mui/icons-material/Send';
import Button from "@mui/material/Button";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {TextField} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from "@mui/material/Typography";

const DriwerMenuPreference = observer(({price, prefType}) => {

	const [open,setOpen] = React.useState(true);
    const {user} = React.useContext(Context)

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
    setAge(event.target.value);
    };
    const list = () => {

        return(
        <Box
            sx={{ width: '100%', height: '95%', background:'white'}}
            role="presentation"

        >   <div className="pref" style={{position: 'relative', display: 'flex', width: '100%', height: '100%'}}>
                <div className="prefin" style={{marginLeft: 'auto',marginRight: 'auto',marginTop: '18%'}}>
                <Typography variant="h6" >
                        Текущий тарифный план Пробный
                    </Typography>
                <FormControl fullWidth style={{marginTop:"20px"}}>
        <InputLabel id="demo-simple-select-label">Тарифный план</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          sx={{width:'200px'}}
          label="Тарифный план"
          onChange={handleChange}
        >
          <MenuItem value={10}>Пробная</MenuItem>
          <MenuItem value={20}>Минимальная</MenuItem>
          <MenuItem value={30}>Экстра</MenuItem>
          <MenuItem value={30}>Премиум</MenuItem>
        </Select>
        </FormControl>
        <div className="prefin2" style={{marginTop:'20px'}}>
        <Button variant="contained">Изменить тариф</Button>
        <Button variant="outlined" style={{marginLeft:'5px'}} onClick={() => user.setDriwerPreference(false)}>Отменить</Button>
        </div>
        <div className="prefin3" style={{marginTop:'20px', width:'340px'}}>
        <Typography variant="body1" component="h2">
        В данном разделе можно изменить тарифный план, после выбора в меню система проверит, хватает ли на вашем балансе средств и либо предложит пополнить баланс, либо ваш тариф будет обновлен. 
        Внимание! Тариф будет действовать ровно 30 дней, начиная с текущего.
        </Typography>
        </div>
            </div>
            </div>
        </Box>)
    }
    return (
            <div>
                <React.Fragment>
                    <SwipeableDrawer
                        PaperProps={{
                            sx: {
                                width: '500px'
                            }
                        }}
                        anchor={'right'}
                        open={user.driwerPreference}
                        onClose={() => {
                            user.setDriwerPreference(false)
                        }}
                        onOpen={() => user.setDriwerPreference(true)}
                    >
                        {list()}

                    </SwipeableDrawer>
                </React.Fragment>

            </div>
    );
});

export default DriwerMenuPreference;