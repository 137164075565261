import {$authHost, $host} from "./index";
import jwtDecode from "jwt-decode";

;

export const registration = async (email,password) => {
    const {data} = await $host.post('api/user/registration', {email,password,role:'ADMIN'})
    localStorage.setItem('token',data.token)
    return jwtDecode(data.token)
}

export const login = async (email,password) => {
    const {data} = await $host.post('api/user/login', {email,password})
    localStorage.setItem('token',data.token)
    return jwtDecode(data.token)
}

export const changePassword = async (password,rePassword) => {
    const {data} = await $authHost.post('api/user/changePassword', {password,rePassword})
    return data
}

export const updatePreference = async (preferenceBody) => {
    const {data} = await $authHost.post('api/user/updatePreference', {preferenceBody})
    return data
}

export const check = async () => {
    const {data} = await $authHost.get('api/user/auth', )
    localStorage.setItem('token',data.token)
    return jwtDecode(data.token)
}

export const check2 = async () => {
    const {data} = await $authHost.get('api/user/auth', )
    let rez = 'Non';
    if(data){
       rez = 'yes'
    }
    return data
}

export const marketplaceData = async (marketplaceType,login,password,lkNumber,tokenAuth,token,image,urlAPI,name,urlPimAPIPrices,urlPimAPI,urlFidSber,storeId) => {
    const {data} = await $authHost.post('api/marketplace',
        {marketplaceType,login,password,lkNumber,tokenAuth,token,image,urlAPI,name,urlPimAPIPrices,urlPimAPI,urlFidSber,storeId})
    return data
}

export const marketplaceContent = async (marketplaceId) => {
    const {data} = await $authHost.post('api/content/getAll', {marketplaceId})
    return data
}

export const allMarketplace = async (storeId) => {
    const data = await $authHost.post(`api/marketplace/getAllMarketplace`,{storeId})
    return data
}

export const getUpdateMarketplace = async () => {
    const token = localStorage.getItem('token')
    const {data} = await $authHost.get(`api/marketplace/isupdate/${token}`)
    return data
}

export const marketplaceUpdate = async (updateIsActive,marketplaceId) => {
    const {data} = await $authHost.post('api/marketplace/update',
        {updateIsActive,marketplaceId})
    return data
}

export const updateNotifyState = async () => {
    const {data} = await $authHost.post('api/user/notifyState',
        {})
    return data
}

export const getNotifyState = async () => {
    const {data} = await $authHost.post('api/user/getNotifyState',
        {})
    return data
}

export const marketplaceUpdateColumn = async (marketplaceId,name,token,tokenAuth,urlFidSber,lkNumber) => {
    const {data} = await $authHost.post('api/marketplace/updateColumn',
        {marketplaceId,name,token,tokenAuth,urlFidSber,lkNumber})
    return data
}

export const marketplaceDelete = async (marketplaceId) => {
    const {data} = await $authHost.post('api/marketplace/delete',
        {marketplaceId})
    return data
}

export const getEmail = async () => {
    const token = localStorage.getItem('token')
    const {data} = await $authHost.get(`api/user/getemail/${token}`,)
    return data
}

export const getBalance = async () => {
    const token = localStorage.getItem('token')
    const {data} = await $authHost.get(`api/user/getbalance/${token}`,)
    return data
}

export const getPreference = async () => {
    const token = localStorage.getItem('token')
    const {data} = await $authHost.get(`api/user/getpreference/${token}`,)
    return data
}

export const getMarketplaceGroupByModel = async (marketplaceId) => {
    const {data} = await $authHost.get(`api/content/groupModel/${marketplaceId}`)
    return data
}

export const getMarketplaceGroupByDate = async (storeId,marketplaces,intervalCalendarFrom,intervalCalendarTo,type) => {
    const {data} = await $authHost.post('api/content/groupDate',
        {storeId,marketplaces,intervalCalendarFrom,intervalCalendarTo,type})
    return data
}

export const getMarketplaceGroupByDateOrSumm = async (storeId,marketplaces,intervalCalendarFrom,intervalCalendarTo,type, variant) => {
    const {data} = await $authHost.post('api/content/groupDateOrSumm',
        {storeId,marketplaces,intervalCalendarFrom,intervalCalendarTo,type,variant})
    return data
}