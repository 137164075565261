export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const SITE_ROUTE = '/'
export const PRICES_ROUTE = '/prices'
export const DASHBOARD_ROUTE = '/dashboard'
export const ANALYTICS_ROUTE = '/analytics'
export const CALCULATOR_ROUTE = '/calculator'
export const PIM_ROUTE = '/pim_system'
export const PIM_SETTINGS = '/pim_settings'
export const SETTINGS_ROUTE = '/settings'
export const PIM_UNITE = '/pim_unite'