import * as React from 'react';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';
import FormControlLabel from '@mui/material/FormControlLabel';
import MediaCard from "./MediaCard";
import Card from "@mui/material/Card";
import {useEffect, useState} from "react";
import {allMarketplace} from "../http/userAPI";
import DriwerMenuPim from "./DriwerMenuPim";
import DialogPimUpdate from "./DialogPimUpdate";
import {Context} from "../index";
import {observer} from "mobx-react-lite";



const SimpleGrow = observer(() => {
    const [checked, setChecked] = React.useState(true);
    const [marketplaces, setMarketplaces] = useState({data:[]})
    const [stateOpen2,setStateOpen2] = useState(false)
    const [stateOpen3,setStateOpen3] = useState(false)
    const [marketplaceId2,setMarketplaceId2] = useState(0)
    const [marketplaceId3,setMarketplaceId3] = useState(0)
    const {user} = React.useContext(Context)

    const updateData = (stateOpen,marketplaceId) => {
        setStateOpen2(stateOpen)
        setMarketplaceId2(marketplaceId)
    }

    const updateData2 = (stateOpen,marketplaceId) => {
        setStateOpen3(stateOpen)
        setMarketplaceId3(marketplaceId)
    }

    useEffect(()=>{
        allMarketplace(user.storeId.id.toString()).then(data => setMarketplaces(data))
    },[user.storeId.name])

    return (
        <Box sx={{ height: 120 }}>

            <Box sx={{ display: 'flex',flexWrap:'wrap' }}>


                {marketplaces.data?.map((market) => (
                <Grow
                    in={checked}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(checked ? { timeout: 1000 } : {})}
                >
                    <Card sx={{ width: '15.41%',minWidth:'150px',margin:'3px'}} >
                        <MediaCard rows={market} updateData={updateData} updateData2={updateData2}/>
                    </Card>

                </Grow>
                ))}
                {stateOpen2 === true ? (
                    <DriwerMenuPim openState={stateOpen2} marketplaceId={marketplaceId2} updateData={updateData}/>
                ):null}
                {stateOpen3 === true ? (
                    <DialogPimUpdate openState={stateOpen3} marketplaceId={marketplaceId3} updateData2={updateData2}/>
                ):null}
            </Box>
        </Box>
    );
})

export default SimpleGrow