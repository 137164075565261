import {$authHost} from "./index";

export const addStore = async (name) => {
    const {data} = await $authHost.post('api/store/addStore',
        {name})
    return data
}

export const getAllStore = async () => {
    const {data} = await $authHost.post('api/store/getAllStore',
        {})
    return data
}

export const getCompare = async (storeId) => {
    const {data} = await $authHost.post('api/store/getCompare',
        {storeId})
    return data
}

export const updateCompare = async (compareId, marketplaces, dateCompare, type, nameLineChart) => {
    const {data} = await $authHost.post('api/store/updateCompare',
        {compareId, marketplaces, dateCompare, type,nameLineChart})
    return data
}

export const deleteCompare = async (compareId) => {
    const {data} = await $authHost.post('api/store/deleteCompare',
        {compareId})
    return data
}

export const addCompare = async (storeId, marketplaces, dateCompare, type, nameLineChart) => {
    const {data} = await $authHost.post('api/store/addCompare',
        {storeId, marketplaces, dateCompare, type, nameLineChart})
    return data
}

export const getAllCompareContent = async (storeId, type) => {
    const {data} = await $authHost.post('api/content/getCompare',
        {storeId, type})
    return data
}

export const getMenu= async (storeId) => {
    const {data} = await $authHost.post('api/store/getMenu',
        {storeId})
    return data
}

export const addMenu= async (storeId,menu) => {
    const {data} = await $authHost.post('api/store/addMenu',
        {storeId,menu})
    return data
}