import React, {useContext, useEffect} from 'react';
import MediaCard from "../components/MediaCard";
import MediaControlCard from "../components/MediaControlCard";
import FormDialog from "../components/FormDialog";
import DataTable from "../components/DataTable";
import IconLabelButtons from "../components/IconLableButtons";
import SimpleGrow from "../components/SimpleGrow";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {getAllProductAllMarketplace, getAllProductColumn} from "../http/marketAPI";
import DataTableDash from "../components/DataTableDash";
import ColorToggleButton from "../components/ToggleButton";
import DialogAddStore from "../components/DialogAddStore";




const Dashboard = observer(() => {

    return (
            <div>
            <MediaControlCard/>
                <div className={'fromd'} style={{justifyContent:'space-between',display:'flex',background:'white',boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',borderRadius:'4px',padding:'3px'}}>
                    <div className={'fromdIn'} style={{display:'flex'}}>
                <FormDialog/>
                        <DialogAddStore/>
                    </div>
                    <ColorToggleButton/>
                </div>
                <SimpleGrow/>
               <DataTableDash/>
            </div>
    );
});

export default Dashboard;