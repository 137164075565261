import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import {useEffect, useState} from "react";
import dayjs from 'dayjs';
const ToggleButtonBlock = observer(() => {

  const analytics = React.useContext(Context)

  const handleChange = (event, newAlignment) => {
    analytics.analytics.setAnalyticMenuDataInterval(newAlignment);
    if(newAlignment === '1'){
    analytics.analytics.setAnalyticMenuDataFrom(dayjs().subtract(7, 'd'))
    analytics.analytics.setAnalyticMenuDataTo(dayjs())
    }
    if(newAlignment === '2'){
    analytics.analytics.setAnalyticMenuDataFrom(dayjs().subtract(30, 'd'))
    analytics.analytics.setAnalyticMenuDataTo(dayjs())
    }
    if(newAlignment === '3'){
    analytics.analytics.setAnalyticMenuDataFrom(dayjs().subtract(1, 'y'))
    analytics.analytics.setAnalyticMenuDataTo(dayjs())
    }
  };

  useEffect(()=>{
      console.log(analytics.analytics.analyticMenuDataInterval)
  },[])

  return (
    <ToggleButtonGroup
      color="primary"
      value={analytics.analytics.analyticMenuDataInterval}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <div style={{textAlign:'center', fontSize:'13px',fontFamily:'"Roboto","Helvetica","Arial",sans-serif',marginRight:'15px',background:'unset'}}>
      <QueryBuilderIcon sx={{width:'30px', height:'30px'}}/>
      <div>Период</div>
      </div>
      <ToggleButton value="1">Неделя</ToggleButton>
      <ToggleButton value="2">Месяц</ToggleButton>
      <ToggleButton value="3">Год</ToggleButton>
    </ToggleButtonGroup>
  );
})

export default ToggleButtonBlock
