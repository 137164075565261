import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const labels = ["2017", "2018", "2019", "2020", "2021", "2022"];

const options = {
   responsive: true,
   maintainAspectRatio: false, 
   borderWidth:1,
  scales: {

  x: {
    border:{
      display: false
    },
    grid: {
      tickColor:'#fff',
      color:'#fff',
      display: false
    },
    ticks: {
      tickColor:'#fff',
      color:'#fff',
        display: false
      }
  },
  y: {
     border:{
      display: false
    },
    ticks: {
      color:'#fff',
        display: false
      },
    grid: {
      color:'#fff',
      display: false
    }
  }
},
  plugins: {
    legend: false
  },
  
};


const CartInfo = ({labels,dataVal, fullSumm, fullVal, type}) => {

  const data = {
  labels,
  datasets: [
    {
      label: "Всего",
      data: dataVal,
      backgroundColor: "white",
      borderColor: "white",
    },
  ],
  };


  return (

    <div className="CartLine" style={{minHeight:180, position: 'relative',marginLeft:'auto',width:'85%', overflow:'hidden', marginBottom:'20px',marginRight:'auto', marginTop:'20px'}}>
    
      <Line options={options} data={data} style={{position:'absolute', zIndex:1}}/>

      <div className="CartText" style={{zIndex:2, margin:20,position:'absolute', color:'white'}}>
      <div className="CartText1">{type}</div>
      <div className="CartText2">{type === 'Выручка' ? fullSumm+'Р' : fullVal+'шт.'}</div>
      
      </div>
   
    </div>
  );
};

export default CartInfo;