import "./style/App.css";
import React, {useContext, useEffect, useState} from "react";
import AppRouter from "./components/AppRouter";
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import {check, check2} from "./http/userAPI";
import {Spinner} from "react-bootstrap";
import {LOGIN_ROUTE} from "./utils/const";
import {CircularProgress, LinearProgress} from "@mui/material";
import Box from '@mui/material/Box';
import loadingGif from './assets/loading.gif';
import { CSSTransition } from 'react-transition-group';

const App = observer(() => {
  const {user} = useContext(Context)
  const [loading,setLoading] = useState(true)
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

  useEffect(async () => {
      await check().then(async data => {
          user.setUser(true)
          user.setIsAuth(true)
      }).finally( async () => {
          await sleep(500).then(r => {
            setLoading(false)
          })
          let action = true
          while (true) {
              action = user.isAuth !== false
          await sleep(10000).then(r => {
              if(action){
                  check2().catch(data => {
                      user.setUser({})
                      user.setIsAuth(false)
                      user.setSnapbar(true, 'warning', 'Ваша сейсия истекла! Войдите снова.')
                  })}
              })


          }
      })

  },[])

  return (
    <>
    <CSSTransition
        in={loading}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
      <div id="loader-wrapper">
      <div id="loading">
        <div class="cssload-loader">
          <div class="cssload-ball cssload-ball-1"></div>
          <div class="cssload-ball cssload-ball-2"></div>
          <div class="cssload-ball cssload-ball-3"></div>
          <div class="cssload-ball cssload-ball-4"></div>
        </div>
      </div>
    </div>
    </CSSTransition>
      <AppRouter/>
      </>
  );
})

export default App;