import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import {useContext, useEffect, useState} from "react";
import {Context} from "../index";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Skeleton, TextField} from "@mui/material";
import {createModified, deleteModified, getModified, getModifiedById, updateStateGrid} from "../http/marketAPI";
import bg from "../assets/back.jpg";
import {observer} from "mobx-react-lite";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const FormRow = observer(() => {
    const [shema, setShema] = React.useState('');
    const {user} = useContext(Context)
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [dataModified, setDataModified] = useState({data:[]});
    const [gridData, setGridData] = useState({data:[]});
    const [name,setName] = React.useState('');

    useEffect(()=>{
         getModified().then(data => setDataModified(data))
    },[])

    useEffect(()=>{
        if(user.inactiveShemaId !== 0){
        getModifiedById(user.inactiveShemaId).then(data => setGridData(data))
            console.log(gridData)
        }
    },[user.toggleDriver])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleAdd = async () => {
        setOpen(false)
        await createModified(name).then()
        await getModified().then(data => setDataModified(data))

        user.setSnapbar(true, 'success', 'Схема добавлена')
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleButton = (event) => {
        user.setCalcButtonId(event.target.id)
        user.setInactiveShemaName(event.target.name)
        user.setToggleDriver(true)
    }
    const handleChange = async (event) => {
        setShema(event.target.value);
        user.setInactiveShemaId(event.target.value)
        user.setInactiveShemaName('')
        await getModifiedById(event.target.value).then(data => setGridData(data))
    };


    const handleDelete = async () => {
        setOpen2(false);
        if(shema===''){
            return user.setSnapbar(true, 'error', 'Схема не выбранна')
        }
        await deleteModified(shema).then()
        await getModified().then(data => setDataModified(data))
        user.setSnapbar(true, 'warning', 'Схема удалена')
        setShema('')
    };


    return (
        <React.Fragment>
            <div className='blockPassword cart' style={{width:'100%',backgroundImage:`url(${bg})`,backgroundSize:'100% 100%',marginRight:'unset'}}>
                <Typography variant="h6" >
                    Информация
                </Typography>
            </div>
            <div className='blockPassword cart' style={{position:"relative", display:"flex", height:'55px',width:'30%',borderRadius:'4px'}}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Схема</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={shema}
                    label="Схема"

                    onChange={handleChange}
                >

                    {dataModified.data?.map((modified,index)=>(
                        <MenuItem value={modified.id}>{modified.name}</MenuItem>
                ))}
                </Select>
            </FormControl>
            </div>
                <Button variant="outlined" sx={{ display: 'flex',marginLeft:'1%',height:'55px' }} onClick={handleClickOpen}>
                    Добавить новую схему
                </Button>
                <Button variant="outlined" sx={{ display: 'flex',marginLeft:'1%',height:'55px',color:'rgb(255 15 15)'}} onClick={handleClickOpen2}>
                    Удалить схему
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Введите название новой схемы"}
                    </DialogTitle>
                    <DialogContent>
                        <TextField id="outlined-basic" onChange={(e) =>
                            setName(e.target.value)} label="Название" variant="outlined" sx={{width:'100%',marginTop:'5px'}}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Отмена</Button>
                        <Button onClick={handleAdd} autoFocus>
                            Создать
                        </Button>
                    </DialogActions>
                </Dialog>

            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Удалить схему"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Внимание! Выбранная схема будет удалена, подтвердите свое действие или нажмите отмену.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose2}>Отмена</Button>
                    <Button onClick={handleDelete} autoFocus>
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
            {!shema ? (
                <Box sx={{ width: '100%' }}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                </Box>) : false}
            {shema ? (
            <div className="blockInfo">
            <div className='blockPassword cart' style={{width:'100%',background:'unset',height:'unset',marginBottom:'2px',marginLeft:'8px',boxShadow:'unset'}}>
                <Typography variant="h6" >

                </Typography>
            </div>

            {gridData.dataColumn?.map((ar)=>(
                <Grid item xs={1}>
                    <Item><Button id={ar.id} onClick={handleButton}>{ar.name}</Button></Item>
                </Grid>
            ))}
            <div className='blockPassword cart' style={{width:'100%',background:'unset',height:'unset',marginBottom:'6px',marginLeft:'8px',boxShadow:'unset',marginTop:'12px'}}>
                <Typography variant="h6" >

                </Typography>
            </div>
            {gridData.stateGrid?.map((ar)=>(
                <Grid item xs={1}>
                    <Item><Button id={ar.id} name={ar.name} onClick={handleButton} sx={{color:ar.color}}>{ar.name}</Button></Item>
                </Grid>
            ))}
            </div> ) : false}
        </React.Fragment>
    );
})

const NestedGrid = () => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
                <Grid container item spacing={1}>
                    <FormRow />
                </Grid>
            </Grid>
        </Box>
    );
}

export default NestedGrid