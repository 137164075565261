import {makeAutoObservable} from "mobx";

export default class UserSite {
    constructor() {
        this._isAuth = false
        this._user = {}
        this._inactive = false
        this._snapbar = {open:false,severity:'',text:''}
        this._autocomplotionArray = {condition:[{condition_data:'',operator:'',variable:''}]}
        this._add = false
        this._addStore = false
        this._stateStoreDialog = false
        this._trigerButtonStateShema = false
        this._marketplaceCheck = 0
        this._delete = false
        this._toggleDriver = false
        this._inactiveShemaId = 0
        this._inactiveShemaName = ''
        this._buttonState = false
        this._calcButtonId = 0
        this._scrollCheck = 0
        this._storeId = {id:'',name:''}
        this._driwerPreference = false
        makeAutoObservable(this)
    }

    setStoreId(id,name){
        this._storeId.id = id
        this._storeId.name = name
    }

    setAddStore(num){
        this._addStore = num
    }

    setDriwerPreference(bool){
        this._driwerPreference = bool
    }

    setStateStoreDialog(num){
        this._stateStoreDialog = num
    }

    setscrollCheck(id){
        this._scrollCheck = id
    }

    setTriggerButtonStateShema(bool){
        this._trigerButtonStateShema = bool
    }

    setCalcButtonId(id){
        this._calcButtonId = id
    }

    setToggleDriver(bool){
        this._toggleDriver = bool
    }

    setButtonState(bool){
        this._buttonState = bool
    }

    setIsAuth(bool){
        this._isAuth = bool
    }

    setDelete(bool){
        this._delete = bool
    }

    setAdd(bool){
        this._add = bool
    }

    setMarketplaceCheck(id){
        this._marketplaceCheck = id
    }

    setSnapbar(open,severity,text){
        this._snapbar.open = open
        this._snapbar.severity = severity
        this._snapbar.text  = text
    }

    setUser(user){
        this._user = user
    }

    setInactive(bool){
        this._inactive = bool
    }

    setInactiveShemaId(id){
        this._inactiveShemaId = id
    }

    setInactiveShemaName(name){
        this._inactiveShemaName = name
    }

    setAutocomplitionArray(arr){
        this._autocomplotionArray = arr
    }

    get storeId() {
        return this._storeId
    }

    get isAuth() {
        return this._isAuth
    }

    get snapbar(){
        return this._snapbar
    }

    get user() {
        return this._user
    }

    get inactive(){
        return this._inactive
    }

    get inactiveShemaId(){
        return this._inactiveShemaId
    }

    get inactiveShemaName(){
        return this._inactiveShemaName
    }

    get add(){
        return this._add
    }

    get delete(){
        return this._delete
    }

    get driwerPreference(){
        return this._driwerPreference
    }

    get marketplaceCheck(){
        return this._marketplaceCheck
    }

    get buttonState(){
        return this._buttonState
    }

    get scrollCheck(){
        return this._scrollCheck
    }

    get toggleDriver(){
        return this._toggleDriver
    }

    get calcButtonId(){
        return this._calcButtonId
    }

    get autocomplitionArray(){
        return this._autocomplotionArray
    }

    get triggerButtonStateShema(){
        return this._trigerButtonStateShema
    }

    get addStore(){
        return this._addStore
    }

    get stateStoreDialog(){
        return this._stateStoreDialog
    }
}