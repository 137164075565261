import React, {useContext,useEffect} from 'react';
import PieChartOne from "../components/PieChart";
import PureComponentZ from "../components/StackedBarChart";
import MixBarChart from "../components/MixBarChart";
import ScrollableTabsButtonVisible from "../components/AnalyticBar";
import DataTable from "../components/DataTable";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import TopBlock from "../components/analyticPage/topBlock/TopBlock"
import Autocomlite2 from "../components/analyticPage/topBlock/Autocomlite2"
import DatePicker2 from "../components/analyticPage/topBlock/DatePicker2"
import ButtonVisualisation from "../components/analyticPage/topBlock/ButtonVisualisation"
import ToggleButtonBlock from "../components/analyticPage/topBlock/ToggleButtonBlock"
import ToggleButtonBlockPie from "../components/analyticPage/topBlock/ToggleButtonBlockPie"
import LineChart from "../components/analyticPage/bodyBlock/LineChart"
import LineChart2 from "../components/analyticPage/bodyBlock/LineChart2"
import DatePickerMarket from "../components/analyticPage/bodyBlock/DatePickerMarket"
import ButtonGroupMarket from "../components/analyticPage/bodyBlock/ButtonGroupMarket"
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { toJS } from 'mobx'
import IconButton from '@mui/material/IconButton';
import Fingerprint from '@mui/icons-material/Fingerprint';
import SettingsIcon from '@mui/icons-material/Settings';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Moment from 'moment'
import {addCompare} from "../http/storeAPI";
import {getCompare} from "../http/storeAPI";
import {getAllCompareContent} from "../http/storeAPI";

const Analytics = observer(() => {
    const {analytics,user} = useContext(Context)
    const [color,setColor] = React.useState('#2a2a35')
    const [open, setOpen] = React.useState(false);
    const [compare, setCompare] = React.useState([])

	  const handleClickOpen = async() => {
        await getCompare(user.storeId.id.toString()).then(data=>{
            console.log(data)
            if(data !== 'Non'){
            setCompare(data)}})
	    setOpen(true);
       
	  };

	  const handleClickAdd = async () => {
        const date = new Date()
        const dateRez = Date.parse(date)
        if(compare.length < 5){
	    await addCompare(user.storeId.id.toString(), [], '01-01-2023', true, dateRez.toString()).then()
        analytics.setCompareTrigger([1,3,4])
	    await getCompare(user.storeId.id.toString()).then(data=>{
	  		console.log(data)
	  		if(data !== 'Non'){
	  		setCompare(data)}})
        }else{
            user.setSnapbar(true, 'warning', 'Не более 5 сравнений!')
        }
	  };

	  const handleClose = () => {
	    setOpen(false);
	  };

      const handleStart = async () => {
        setOpen(false);
        analytics.setCompareTrigger(compare)
      };

    const handleChange = (event) => {
      analytics.setCompareInterval(event.target.checked);
    };

	  useEffect(()=>{
	  	getCompare(user.storeId.id.toString()).then(data=>{
	  		console.log(data)
	  		if(data !== 'Non'){
	  		setCompare(data)}})
	  },[user.storeId.id, analytics.compareTrigger])



    useEffect(()=>{
        const rez = toJS(analytics.analyticMenuDataMarketplaces)
        console.log(rez)
        if(!rez?.[1] && rez?.[0]?.['id'] === 1){    
            setColor('blue')
        }
        if(!rez?.[1] && rez?.[0]?.['id'] === 2){ 
            setColor('#e360fa')
        }
        if(!rez?.[1] && rez?.[0]?.['id'] === 3){ 
            setColor('#008000')
        }
        if(!rez?.[1] && rez?.[0]?.['id'] === 4){ 
            setColor('#dbc558')
        }
        if(rez?.[1] || !rez?.[0]){ 
            setColor('#2a2a35')
        }

    },[analytics.analyticMenuDataMarketplaces])

    const admin = true
    return (
        <>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Настройки сравнения продаж"}
          <IconButton aria-label="fingerprint" color="secondary" sx={{position:'absolute',zIndex:2, right:0}} onClick={handleClickAdd}>
        		<AddBoxIcon />
      		</IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControlLabel control={
            <Switch 
                checked={analytics.compareInterval}
                onChange={handleChange}
           />
          } label="Сравнение по году (вкл) / месяцу" />
          {compare?.map((data)=>(
          <div>
          <ButtonGroupMarket key={data.id} id={data.id} marketplaces={data.marketplaces}/>
          <DatePickerMarket key={data.id+'z'} id={data.id} dateCompare={data.dateCompare} name={data.nameLineChart}/>

          </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отменить</Button>
          <Button onClick={handleStart} autoFocus>
            Применить
          </Button>
        </DialogActions>
      </Dialog>
        {admin ? (
        <div class="blockInfo">
            <div className="menyAnalytic" style={{top:'20px',display:'flex', justifyContent:'center',boxShadow:'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px', background:'white', borderRadius:'15px',width:'100%',marginBottom:'14px'}}>
                Сравнение данных продаж
            </div>
            <div className="blockInfo cartInfo" style={{transition:'all 2s cubic-bezier(0.46, 0.03, 0.52, 0.96) 0s',width:'100%',height:500, marginBottom:'35px',border:'5px solid', borderRadius: '22px', borderColor:'black',position:'relative'}}>
            <IconButton aria-label="fingerprint" color="secondary" sx={{position:'absolute', top:'-4px', zIndex:2, right:0}} onClick={handleClickOpen}>
                <SettingsIcon />
            </IconButton>
            <LineChart2/>
            </div>
        	<TopBlock/>
            
            <div className="menyAnalytic" style={{position:'sticky',zIndex:222,top:'20px',boxShadow:'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',display:'block', background:'white', borderRadius:'15px',width:'100%',marginBottom:'40px',paddingLeft:'16px',paddingRight:'16px', paddingTop:'14px'}}>
        	<div className="menyAutocomplite">
        	<Autocomlite2 />
        	</div>
            <div className='menuButton'style={{marginTop:'10px', marginBottom:'10px',display:'flex', flexWrap:'wrap',justifyContent:'space-between'}}>
        	<div className='ButtonGroup' style={{margin:'15px',display:'flex',flexWrap:'wrap'}}>
            <ToggleButtonBlock/>
    		</div>
            <div className='DatePicker'>
            <DatePicker2/>
            </div>
            </div>
            </div>
            <div className="menyAnalytic" style={{top:'20px',display:'flex', justifyContent:'center',boxShadow:'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px', background:'white', borderRadius:'15px',width:'100%',marginBottom:'14px'}}>
            	Аналитика продаж
            </div>
            <div className="blockInfo cartInfo" style={{transition:'all 2s cubic-bezier(0.46, 0.03, 0.52, 0.96) 0s',width:'73%',height:500, marginBottom:'35px', marginRight:'1%',border:'5px solid', borderRadius: '22px', borderColor:color,position:'relative'}}>
                <LineChart/>
            </div>
            <div class="blockInfo cartInfo" style={{width:'26%',height:500, position:'relative',zIndex:0,boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px"}}>
                <ToggleButtonBlockPie/>
                <PieChartOne MarketID={2}/>
            </div>
            <div className="blockInfo cartInfo">
                <DataTable MarketID={analytics.analyticMenuDataMarketplaces} Column={1}/>
            </div>
        </div>
        ) : <div style={{ height: 500, width: '100%', background:'white',borderRadius:'4px'}}><div className={'dataInf2'} style={{ height: '100%', width: '100%', background:`url(${require(`../assets/reconstruktion.png`)})`,backgroundSize: '100% 100%'}}/></div>}
        </>
    );
});

export default Analytics;