import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {useContext} from "react";
import {Context} from "../index";
import {observer} from "mobx-react-lite";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomizedSnackbars = observer(() => {

    const {user} = useContext(Context)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        user.setSnapbar(false,user.snapbar["severity"],user.snapbar["text"]);
    };

    return (
        <>
            <Snackbar open={user.snapbar["open"]} autoHideDuration={10000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleClose} severity={user.snapbar["severity"]}>
                    {user.snapbar["text"]}
                </Alert>
            </Snackbar>
        </>
    );
})

export default CustomizedSnackbars