import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import TodayIcon from '@mui/icons-material/Today';
import 'dayjs/locale/ru'
import {Context} from "../../../index";
import {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";

const DatePicker2 = observer(() =>{
  const [value, setValue] = React.useState(dayjs());
  const [value2, setValue2] = React.useState(dayjs().subtract(30, 'd'));

  const analytics = React.useContext(Context)

  useEffect(()=>{
      console.log(analytics.analytics.analyticMenuDataFrom)
  },[])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      <DemoContainer components={['DatePicker', 'DatePicker']}>
      <div style={{textAlign:'center', fontSize:'13px',fontFamily:'"Roboto","Helvetica","Arial",sans-serif',marginTop:'8px',background:'unset'}}>
      <TodayIcon sx={{width:'30px', height:'30px'}}/>
      <div>Интервал</div>
      </div>
        <DatePicker label="Начальная дата" 
          value={analytics.analytics.analyticMenuDataFrom}
          onChange={(newValue) => {
            analytics.analytics.setAnalyticMenuDataFrom(newValue)
            analytics.analytics.setAnalyticMenuDataInterval('11')
            setTimeout(1000);
            analytics.analytics.setAnalyticMenuDataInterval('4')
          }}
         />
        <DatePicker
          label="Конечная дата"
          value={analytics.analytics.analyticMenuDataTo}
          onChange={(newValue) => {
            analytics.analytics.setAnalyticMenuDataTo(newValue)
            analytics.analytics.setAnalyticMenuDataInterval('11')
            setTimeout(1000);
            analytics.analytics.setAnalyticMenuDataInterval('4')
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
})

export default DatePicker2