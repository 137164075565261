import * as React from 'react';
import { DataGrid,GridToolbar,GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExport} from '@mui/x-data-grid';
import {useContext, useEffect, useState} from "react";
import {allMarketplace, getUpdateMarketplace, marketplaceContent} from "../http/userAPI";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import PropTypes from "prop-types";
import {getAllProductAllMarketplace, getAllProductColumn} from "../http/marketAPI";
import bg from "../assets/back.jpg";
import {CircularProgress, LinearProgress} from "@mui/material";
import Box from "@mui/material/Box";
import loadingGif from '../assets/loading.gif';





const DataTableDash = observer(() => {

    function CustomToolbar() {
    return (
    <GridToolbarContainer sx={{float:'right', marginTop:'-37px'}}>
      <GridToolbarExport csvOptions={{
    delimiter: ';',
    utf8WithBom: true,
  }}/>
    </GridToolbarContainer>
  );
}

    const [columns,setColumns] = React.useState([])
    const [rows,setRows] = React.useState([])
    const [timer,setTimer] = React.useState(true)
    const {user} = React.useContext(Context)
    const [action,setAction] = React.useState(true)
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    useEffect( () => {
    			setTimer(true)
                getAllProductColumn(user.storeId.id.toString()).then(data => {
                    let arr = []
                    let buf = 0
                    for (const val of data) {
                        const image = val['image']
                        val.renderHeader = () => (
                            <strong>
                                {val['field'] !== 'category' && val['field'] !== 'brand' && val['field'] !== 'name' ? (
                                <div className={'tableIn'} style={{width:150,height:100,backgroundImage:`url(${require(`../assets/${image}`)})`,backgroundSize: '100% 100%'}}>
                                    <div className={'tableIn2'} style={{backgroundColor:'rgb(17 116 231 / 81%)',height:'42px',borderRadius:'30px',paddingLeft:'15px',paddingTop:'4px',fontFamily:'initial', color:'white'}}>
                                    {val['headerName']}
                                    </div>
                                </div>
                                ) : <span>{val['headerName']}</span>}
                            </strong>
                        )
                        console.log(val['image'])
                        buf++
                        delete val['image']
                        arr.push(val)
                    }
                    setColumns(arr)
                })

                    getAllProductAllMarketplace(user.storeId.id.toString()).then(data => setRows(data)).finally(data2 => {
                        sleep(500).then(r => {
                            setTimer(false)
                        })
                        
                    })


    },[user.storeId.id])


    return (
        <>
        {timer ? (
        <div style={{ height: 0, width: '100%', background:'white',borderRadius:'4px'}}>
            <Box sx={{ width: '100%', display:'flex', justifyContent:'end', alignContent:'center' }}>
                    <img src={loadingGif} style={{width:'70px'}}/>
                </Box>
        </div>): columns?.[0] && rows?.[0] ? (<div style={{ height: 500, width: '100%', background:'white',borderRadius:'4px'}}>
            <DataGrid
                sx={{marginTop:'34px'}}
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar 
                }}
                componentsProps={{ toolbar: { csvOptions: { delimiter: ';', utf8WithBom: true } } }}
            />
        </div>) : null
            }
            </>
    );
})


export default DataTableDash