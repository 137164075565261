import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect} from "react";
import {updateProductCheckInMarketplace} from "../http/marketAPI";
import {Context} from "../index";

const DialogPimUpdate = ({openState, marketplaceId, updateData2}) => {
    const [open, setOpen] = React.useState(false);
    const {user} = React.useContext(Context)

    useEffect(()=>{
        setOpen(openState)
    },[])


    const handleClose = () => {
        setOpen(false);
        updateData2(false,marketplaceId)
    };

    const handleAdd = () => {
        setOpen(false);
        updateProductCheckInMarketplace(marketplaceId).then()
        user.setSnapbar(true,'success', 'Магазин добавлен в очередь на обновление')
        updateData2(false,marketplaceId)
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Обновить товары в выбранном маркетплейсе?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Внимание! После нажатия на кнопку "Подтвердить" будет создано задание на обновление списка товаров из выбранного маркетплейса. Данные обновляются в течении 5-10 минут. Старый перечнь товаров с ценами будет заменен загруженными в данный момент!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <Button onClick={handleAdd} autoFocus>
                        Подтвердить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DialogPimUpdate