import {makeAutoObservable} from "mobx";
import dayjs from 'dayjs';
export default class AnalyticsSite {
    constructor() {
        this._marketplace = [
            {id: 1, login:'Ysds',password:'asdasd',lkNumber: '2324',tokenAuth: 'sadasf',token:'saadf'},
        ]
        this._marketplaceContent = [
        {   id:1,
            gNumber: 'dssd3r',
            date:'23.05.2022',
            lastChangeDate: 'dsfsdf',
            suplierArticle: 'dsfsdf',
            teachSize: 'dsfsdf',
            barcode: '3244DSASD',
            quantity: '4',
            totalPrice: '324234',
            discountPercent: '23234',
            warehouseName:'234324',
            oblast: '234234',
            odid: '234234',
            nmid: '32432',
            subject: 'dsfsdfsdfsd',
            category: 'Meatgrinder',
            brand: 'Dauken',
            is_cancel: 'no',
            incomeID: '3242342'},
        ]
        this._marketplaceContentModified = [
            {   id:1,
            gNumber: 'dssd3r',
            date:'23.05.2022',
            lastChangeDate: 'dsfsdf',
            suplierArticle: 'dsfsdf',
            teachSize: 'dsfsdf',
            barcode: '3244DSASD',
            quantity: '4',
            totalPrice: '324234',
            discountPercent: '23234',
            warehouseName:'234324',
            oblast: '234234',
            odid: '234234',
            nmid: '32432',
            subject: 'dsfsdfsdfsd',
            category: 'Meatgrinder',
            brand: 'Dauken',
            is_cancel: 'no',
            incomeID: '3242342'},
    ]
        this._analyticMenuDataFrom = dayjs().subtract(7, 'd')
        this._analyticMenuDataTo = dayjs()
        this._analyticMenuDataInterval = '1'
        this._analyticMenuDataPie = '1'
        this._analyticMenuDataMarketplaces = []
        this._analyticMenuDataMarketplaces2 = []
        this._compareInterval = true
        this._compareTrigger = []
        makeAutoObservable(this)
    }

    setMarketplace(marketplace){
        this._marketplace = marketplace
    }

    setCompareInterval(bool){
        this._compareInterval = bool
    }

    setCompareTrigger(compare){
        this._compareTrigger = compare
    }

    setAnalyticMenuDataFrom(intervalCalendarFrom){
        this._analyticMenuDataFrom = intervalCalendarFrom
    }

    setAnalyticMenuDataMarketplaces(marketplaces){
        this._analyticMenuDataMarketplaces = []
        this._analyticMenuDataMarketplaces = marketplaces
    }

    setAnalyticMenuDataMarketplaces2(marketplaces){
        this._analyticMenuDataMarketplaces2 = marketplaces
    }

    setAnalyticMenuDataInterval(interval){
        this._analyticMenuDataInterval = interval
    }

    setAnalyticMenuDataPie(interval){
        this._analyticMenuDataPie = interval
    }

    setAnalyticMenuDataTo(intervalCalendarTo){
        this._analyticMenuDataTo = intervalCalendarTo
    }

    setMarketplaceContent(marketplaceContent){
        this._marketplaceContent = marketplaceContent
    }

    setMarketplaceContentModified(marketplaceContentModified){
        this._marketplaceContentModified = marketplaceContentModified
    }

    get marketplace() {
        return this._marketplace
    }

    get compareInterval() {
        return this._compareInterval
    }

    get compareTrigger() {
        return this._compareTrigger
    }

    get analyticMenuDataTo() {
        return this._analyticMenuDataTo
    }

    get analyticMenuDataInterval(){
        return this._analyticMenuDataInterval
    }

    get analyticMenuDataPie(){
        return this._analyticMenuDataPie
    }

    get analyticMenuDataFrom() {
        return this._analyticMenuDataFrom
    }

    get marketplaceContent() {
        return this._marketplaceContent
    }

    get analyticMenuDataMarketplaces() {
        return this._analyticMenuDataMarketplaces
    }

    get analyticMenuDataMarketplaces2() {
        return this._analyticMenuDataMarketplaces2
    }

    get marketplaceContentModified(){
        return this._marketplaceContentModified
    }
}