import {$authHost} from "./index";

export const WildberriesAuthState = async (token,urlAPI) => {
    const {data} = await $authHost.post('api/marketplace/statustoken',
        {token,urlAPI})
    let isTrueSet = (data === 'true');
    return isTrueSet
}

export const createModified = async (name) => {
    const {data} = await $authHost.post('api/modified/create',
        {name})
    return data
}

export const getAllProductsPim = async (marketplaceId) => {
    const {data} = await $authHost.post('api/marketplace/getAllPim',
        {marketplaceId})
    return data
}

export const getAllLineChart = async (storeId,marketplaces,intervalCalendarFrom,intervalCalendarTo,type) => {
    const {data} = await $authHost.post('api/content/getAllLineChart',
        {storeId,marketplaces,intervalCalendarFrom,intervalCalendarTo,type})
    return data
}

export const getAllUserProductsPim = async () => {
    const {data} = await $authHost.post('api/marketplace/getAllUserProductPim',
        {})
    return data
}

export const updateUnite = async (product,uniteProduct,dataTable) => {
    const {data} = await $authHost.post('api/marketplace/updateUnite',
        {product,uniteProduct,dataTable})
    return data
}

export const deleteUnite = async (idProduct,storeId) => {
    const {data} = await $authHost.post('api/marketplace/deleteUnite',
        {idProduct,storeId})
    return data
}

export const deleteUniteColumn = async (storeId) => {
    const {data} = await $authHost.post('api/marketplace/deleteUniteColumn',
        {storeId})
    return data
}

export const updateUnite2 = async (idProduct,product,uniteProduct) => {
    const {data} = await $authHost.post('api/marketplace/updateUnite2',
        {idProduct,product,uniteProduct})
    return data
}

export const getAllUnite = async (storeId) => {
    const {data} = await $authHost.post('api/marketplace/getAllUnite',
        {storeId})
    return data
}

export const getAllUniteColumn = async (storeId) => {
    const {data} = await $authHost.post('api/marketplace/getAllUniteColumn',
        {storeId})
    return data
}

export const createUpdateNotify = async (marketplaceId,type,name,marketName) => {
    const {data} = await $authHost.post('api/marketplace/notifyAdd',
        {marketplaceId,type,name,marketName})
    return data
}

export const updateProductPricePim = async (productId,newPrice,oldPrice,dateFrom,dateTo) => {
    const {data} = await $authHost.post('api/marketplace/updateProductPricePim',
        {productId,newPrice,oldPrice,dateFrom,dateTo})
    return data
}

export const updateProductStatusPrice = async (marketplaceId) => {
    const {data} = await $authHost.post('api/marketplace/updateProductStatusPrice',
        {marketplaceId})
    return data
}

export const updateProductCheckInMarketplace = async (marketplaceId) => {
    const {data} = await $authHost.post('api/marketplace/updateProductCheckInMarketplace',
        {marketplaceId})
    return data
}

export const updateProductPimChecked = async (productId,checkedIn) => {
    const {data} = await $authHost.post('api/marketplace/updateProductPimChecked',
        {productId,checkedIn})
    return data
}

export const getAllProductByMarketId = async (marketplaceId,storeId) => {
    const {data} = await $authHost.post('api/marketplace/getAllProductsPimByMarketId',
        {marketplaceId,storeId})
    return data
}

export const getAllProductAllMarketplace = async (storeId) => {
    const {data} = await $authHost.post('api/marketplace/getAllProductAllMarketplace',
        {storeId})
    return data
}

export const getAllProductColumn= async (storeId) => {
    const {data} = await $authHost.post('api/marketplace/getAllProductColumn',
        {storeId})
    return data
}

export const getModified = async () => {
    const data = await $authHost.get(`api/modified/getAll`)
    return data
}

export const getModifiedById = async (id) => {
    const {data} = await $authHost.post(`api/modified/getById`,{id})
    return data
}

export const updateModifiedById = async (id,dataShem) => {
    const {data} = await $authHost.post(`api/modified/update`,{id,dataShem})
    return data
}

export const updateStateGrid = async (id,dataStateGrid) => {
    const {data} = await $authHost.post(`api/modified/updateStateGrid`,{id,dataStateGrid})
    return data
}

export const deleteModified = async (modifiedId) => {
    const {data} = await $authHost.post('api/modified/delete',
        {modifiedId})
    return data
}

