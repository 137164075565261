import * as React from 'react';

import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Grow from '@mui/material/Grow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {useContext, useEffect, useState} from "react";
import {
    allMarketplace,
    marketplaceDelete,
    marketplaceUpdate,
    marketplaceUpdateColumn
} from "../http/userAPI";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';


const SimpleGrow = observer(() => {
    const [idMarketplace, setIdMarketplace] = React.useState();
    const label = {inputProps: {'aria-label': 'Switch demo'}};
    const [marketplaces, setMarketplaces] = useState({data: []})
    const [checked, setChecked] = useState(false)
    const {user} = useContext(Context)
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [name, setName] = React.useState('Not');
    const [token, setToken] = React.useState('Not');
    const [tokenAuth, setTokenAuth] = React.useState('Not');
    const [type, setType] = React.useState('Not');
    const [urlFidSber, setUrlFidSber] = React.useState('Not');
    const [lkNumberOzon, setLkNumberOzon] = React.useState('');
    const [triger, setTriger] = React.useState(true);
    
    useEffect(() => {
        setChecked(false)
        allMarketplace((user.storeId.id).toString()).then(data => {
            setMarketplaces(data)
            if(!data.data?.[0]){
                        console.log(data.data?.[0])
                        setTriger(false)
                }
        })
        setChecked(true)
    }, [user.add, user.storeId.id])

    const handleClickOpen = (event) => {
        setOpen(true);
        setIdMarketplace(event.target.id)
    };

    const handleClickOpen2 = (event) => {
        setOpen2(true);
        setType(event.target.attributes.getNamedItem('typemarket').value);
        setName(event.target.attributes.getNamedItem('name').value);
        setToken(event.target.attributes.getNamedItem('token').value);
        setTokenAuth(event.target.attributes.getNamedItem('tokenauth').value);
        setUrlFidSber(event.target.attributes.getNamedItem('urlfidsber').value);
        setLkNumberOzon(event.target.attributes.getNamedItem('lnummarket').value);
        setIdMarketplace(event.target.id)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleChangeTextName = (event) => {
        setName(event.target.value);
    };

    const handleChangeTextName2 = (event) => {
        setToken(event.target.value);
    };

    const handleChangeTextName3 = (event) => {
        setTokenAuth(event.target.value);
    };

    const handleChangeTextName4 = (event) => {
        setUrlFidSber(event.target.value);
    };

    const handleChangeTextName5 = (event) => {
        setLkNumberOzon(event.target.value);
    };

    const handleChangeSwich = (event) => {
        marketplaceUpdate(event.target.checked, event.target.id).then()
        if (event.target.checked == true) {
            user.setSnapbar(true, 'success', 'Маркетплейс добавлен в очередь на обновления')
        } else {
            user.setSnapbar(true, 'warning', 'Маркетплейс убран из очереди на обновления')
        }
    };


    const handleDelete = async () => {
        setOpen(false)
        await marketplaceDelete(idMarketplace).then()
        await allMarketplace(user.storeId.id.toString()).then(data => setMarketplaces(data))
        user.setSnapbar(true, 'success', 'Маркетплейс удален')
    }

    const handleEdit = async () => {
        setOpen2(false)
        await marketplaceUpdateColumn(idMarketplace, name, token, tokenAuth, urlFidSber, lkNumberOzon).then()
        await allMarketplace(user.storeId.id.toString()).then(data => setMarketplaces(data))
        user.setSnapbar(true, 'success', 'Данные маркетплейса успешно изменены')
    }


    return (

        <Box sx={{marginTop: '22px', marginBottom: '22px'}}>
        {marketplaces.data?.[0] ? (
            <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                <Dialog
                    key='dialog1'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Вы действительно хотите удалить маркетплейс из системы?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Внимание! Выбранный маркетплейс будет удален, подтвердите свое действие или нажмите отмену.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Отмена</Button>
                        <Button onClick={handleDelete} sx={{color: 'red'}} autoFocus>
                            Удалить
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    key='dialog2'
                    open={open2}
                    onClose={handleClose2}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Настройки маркетплейса"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Внимание! Тут можно поменять авторизационные данные маркетплейса.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            placeholder={name}
                            margin="dense"
                            id="name"
                            label="Название"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleChangeTextName}
                        />
                        <TextField
                            autoFocus
                            placeholder={token}
                            margin="dense"
                            id="token"
                            label="Токен доступа"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleChangeTextName2}
                        />
                        {type === 'YandexDBS' ? (<TextField
                            autoFocus
                            placeholder={tokenAuth}
                            margin="dense"
                            id="tokenAuth"
                            label="Токен Auth"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleChangeTextName3}
                        />) : null}
                        {type === 'Sbermarket' ? (<TextField
                            autoFocus
                            placeholder={urlFidSber}
                            margin="dense"
                            id="urlFidSber"
                            label="URL Фида для загрузки товаров"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleChangeTextName4}
                        />) : null}
                        {type === 'Ozon' ? (<TextField
                            autoFocus
                            placeholder={lkNumberOzon}
                            margin="dense"
                            id="lkNumberOzon"
                            label="Номер личного кабинета"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleChangeTextName5}
                        />) : null}
                        <FormControl sx={{marginTop: '22px'}}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Первичная загрузка данных</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="female" control={<Radio/>} label="За текущий месяц"/>
                                <FormControlLabel value="male" control={<Radio/>} label="За пол года"/>
                                <FormControlLabel value="other" control={<Radio/>} label="Все данные"/>
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose2}>Отмена</Button>
                        <Button onClick={handleEdit} sx={{color: 'red'}} autoFocus>
                            Изменить
                        </Button>
                    </DialogActions>
                </Dialog>
                {marketplaces.data?.map((market, index) => (
                    <Grow
                        key={market.id + 'grow'}
                        in={checked}
                        style={{transformOrigin: '0 0 0'}}
                        {...(checked ? {timeout: 1000} : {})}
                    >
                        <Card sx={{width: '24.44%', minWidth: '250px', margin: '3px'}} key={market.id}>
                            <Switch {...label}
                                    key={market.id + 'switch'}
                                    id={market.id.toString()}
                                    onChange={handleChangeSwich}
                                    defaultChecked={market.updateIsActive}/>
                            <IconButton id={market.id} onClick={handleClickOpen} sx={{float: 'right'}}
                                        key={market.id + 'button1'}>
                                <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                     focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="DeleteIcon"
                                     id={market.id} onClick={handleClickOpen}>
                                    <path
                                        d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                                        id={market.id} onClick={handleClickOpen}/>
                                </svg>
                            </IconButton>
                            <IconButton key={market.id + 'button2'} id={market.id} typemarket={market.marketplaceType}
                                        token={market.token} tokenauth={market.tokenAuth} name={market.name}
                                        urlfidsber={market.urlFidSber} lnummarket={market.lkNumber}
                                        onClick={handleClickOpen2} sx={{float: 'right'}}>
                                <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                     focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="EditIcon"
                                     id={market.id} typemarket={market.marketplaceType} token={market.token}
                                     tokenauth={market.tokenAuth} name={market.name} urlfidsber={market.urlFidSber}
                                     lnummarket={market.lkNumber} onClick={handleClickOpen2}>
                                    <path
                                        d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                                        id={market.id} typemarket={market.marketplaceType} token={market.token}
                                        tokenauth={market.tokenAuth} name={market.name} urlfidsber={market.urlFidSber}
                                        lnummarket={market.lkNumber} onClick={handleClickOpen2}/>
                                </svg>
                            </IconButton>
                            <CardMedia
                                key={market.id + 'media'}
                                component="img"
                                height="140"
                                image={require(`../assets/${market.image}`)}
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography variant="h5" component="div" key={market.id + 'typo'}>
                                    {market.name}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grow>
                ))}
            </Box>
            ) : triger ? (<div className="stack" style={{display:'flex', height:'260px'}}>
    <Stack spacing={1}>
      <Skeleton variant="rectangular" width={260} height={20} />
      <Skeleton variant="circular" width={50} height={40} />
      <Skeleton variant="rectangular" width={260} height={70} />
      <Skeleton variant="rounded" width={260} height={70} />
    </Stack>
    </div>) : <div className={'dataInfo'} style={{ height: '252px', width: '100%', background:`url(${require(`../assets/banner2.png`)})`, backgroundSize: '100% 100%'}}/>
}
        </Box>

    );
})

export default SimpleGrow;
