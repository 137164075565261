import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import {useContext, useEffect, useState} from "react";
import {Context} from "../index";
import {LOGIN_ROUTE} from "../utils/const";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {
    getBalance,
    getEmail,
    getNotifyState,
    getPreference,
    getUpdateMarketplace,
    updateNotifyState
} from "../http/userAPI";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {Helmet} from "react-helmet"
import AlignItemsList from "./subMaterialComponents/NotifyList";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Socket from '../network/socket'
import DriwerMenuPreference from "../components/DriwerMenuPreference";

const ResponsiveAppBar = observer(() => {
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [balance, setBalance] = React.useState(0);
    const [preference, setPreference] = React.useState('');
    const [notifyState, setNotifyState] = React.useState(false);

    useEffect(()=>{
        getBalance().then(data => setBalance(data))
        getPreference().then(data2 => setPreference(data2))
    },[])


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }

    const handleClose2 = () => {
        setOpen2(false);
    };

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [email,setEmail] = React.useState('Non')
    const {user} = useContext(Context);
    const navigate = useNavigate()

    const logOut = () => {
        user.setUser({});
        user.setIsAuth(false);
        localStorage.clear()
        navigate(LOGIN_ROUTE)
    }
    const [socket,setSocket] = React.useState(null)

    useEffect(()=>{
       getEmail().then(data => setEmail(data))
        setSocket(Socket)
    },[])

    useEffect(()=>{
        if(email !== 'Non'){
        socket?.emit('newUser', email)}
    },[socket,email])

    useEffect(()=>{
        if(socket!==null) {
            socket.on('notifyState', (data) => {
                user.setAdd(true)
		console.log("статус" + user.add)
                setNotifyState(data['notifyState'])
                user.setAdd(false)
            })
        }
    },[socket])


    console.log(email)
    let state = 37;
    if(user.inactive === true){
        state = 10;
    }

    const handleOpenUserMenu = (event) => {
        setNotifyState(true)
        setAnchorElUser(event.currentTarget);
    };

    const handleOpenDialog =  () => {
        user.setStateStoreDialog(true)
    };

    const handleOpenDialogPref =  () => {
        user.setDriwerPreference(true)
    };

    const handleOpenUserMenu2 = () => {
        setOpen2(true);
    };

    const handleCloseUserMenu = async () => {
        setAnchorElUser(null);
        await updateNotifyState().then(state => setNotifyState(state))
    };

    return (
        <AppBar position="static"
        sx={{marginLeft:state,width:"auto",transition: "margin-left .2s ease-in",backgroundColor:'unset',background:'linear-gradient(to right,#b823f8 0,#36c0e8 100%) no-repeat'}}
        >
            <Container maxWidth="xl" >
                <Toolbar disableGutters
                sx={{["@media (min-height:600px)"]: { minHeight: "unset" }}}
                >

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }}}>
                    </Box>
                    <Typography  sx={{marginRight:'2%'}}>
                        <Button><a href="https://overplace.ru/" style={{color: 'white', textDecoration:'none'}}>Промо</a> </Button>
                    </Typography>
                    <Typography  sx={{marginRight:'2%'}}>
                        <Button onClick={handleOpenDialog} sx={{color:'white'}}>Магазин: {user.storeId.name === '' ? 'Не выбран' : user.storeId.name}</Button>
                    </Typography>
                    <Typography  sx={{marginRight:'2%'}}>
                        <Button onClick={handleOpenDialogPref} sx={{color:'white'}}>Версия: {preference}</Button>
                    </Typography>
                    <Typography  sx={{marginRight:'2%'}}>
                        Баланс: {balance} ₽
                    </Typography>
                    <Button variant="outlined" onClick={handleClickOpen} sx={{marginRight:'2%', color:'#fff',border:'1px solid rgb(255 255 255 / 50%)'}}>Пополнить</Button>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >

                        <DialogContent>
                            <Helmet>
                                <script src="https://info.paymaster.ru/pm-widget-test/script/widget.bundle.js"/>
                                <script type='text/javascript' async defer>
                                    {`var PM_WIDGET_CONFIG_1653427687661 = 
  /* начало конфигурации виджета */
  {"version":"1.0","invoice":{"description":"Пополнение баланса","amount":13990,"merchantId":"9c175695-8d5e-45a1-a911-57533ae51f45"},"paymentForm":{"title":"Покупка в интернет магазине","value":{"description":{"type":"input","label":"Пополнение баланса","access":"hidden"},"amount":{"type":"money","label":"Сумма оплаты","placeholder":"100"},"merchantId":{"type":"input"}}},"payment":{"title":null,"submitText":"Купить","allowExternal":true,"methods":["bankcard","sbp","wmr","wmp"]}}
  /* конец конфигурации виджета */
  var widget_1653427687661 = new PayMasterWidget();
  widget_1653427687661.mount('pm-widget-1653427687661', PM_WIDGET_CONFIG_1653427687661);`}
                                </script>
                            </Helmet>
                            <div id="pm-widget-1653427687661"/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        key='dialog1'
                        open={open2}
                        onClose={handleClose2}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Вы действительно хотите выйти из системы?"}
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose2}>Отмена</Button>
                            <Button onClick={() => logOut()} sx={{color:'red'}} autoFocus>
                                Выйти
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Box sx={{ flexGrow: 0}}>
                        <Tooltip title="Уведомления">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 ,marginRight:'10px'}}>
                                {!notifyState ? (
                                <NotificationsNoneIcon sx={{ fontSize: 35,color:'white'}}/>) :
                                    <NotificationsActiveIcon sx={{ fontSize: 35,color:'white'}}/>
                                }
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '25px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <AlignItemsList/>
                        </Menu>
                    </Box>
                    <Tooltip title="Выход">
                        <IconButton onClick={handleOpenUserMenu2} sx={{ p: 0 }}>
                            <ExitToAppIcon sx={{ fontSize: 35,color:'white' }}/>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </Container>
            <DriwerMenuPreference/>
        </AppBar>
    );
});
export default ResponsiveAppBar;
