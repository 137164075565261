import React, {useContext} from 'react';
import NestedGrid from "../components/GridTable";
import SwipeableTemporaryDrawer from "../components/DriwerMenu";
import {observer} from "mobx-react-lite";
import {Context} from "../index";

const Calculator = observer(() => {
    const {user} = useContext(Context)
    const admin = true
    return (
        <>
            {admin ? (
        <div>
            <NestedGrid/>
            <SwipeableTemporaryDrawer/>
        </div>
            ) : <div style={{ height: 500, width: '100%', background:'white',borderRadius:'4px'}}><div className={'dataInf2'} style={{ height: '100%', width: '100%', background:`url(${require(`../assets/reconstruktion.png`)})`,backgroundSize: '100% 100%'}}/></div>}
        </>
    );
});

export default Calculator;