import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import bglogin from "../assets/bglogin.jpg"
import {login, registration} from "../http/userAPI";
import {DASHBOARD_ROUTE, LOGIN_ROUTE, REGISTRATION_ROUTE} from "../utils/const";
import {useContext} from "react";
import {Context} from "../index";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {LinearProgress} from "@mui/material";


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Overplace.ru
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}



const theme = createTheme();

const Auth = observer(() => {

    const isLogin = window.location.pathname  === LOGIN_ROUTE
    const {user} = useContext(Context);
    const navigate = useNavigate()
    const [timer,setTimer] = React.useState(false)
    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time)
        )
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setTimer(true)
        const data = new FormData(event.currentTarget);
        try {
            let userData;
        if(isLogin){
            const userData = await login(data.get('email'),data.get('password'))
        }else {
            const userData = await registration(data.get('email'), data.get('password'))
        }
            user.setUser(user)
            user.setIsAuth(true)
            setTimer(false)
            navigate(DASHBOARD_ROUTE)

        }catch (e){
            setTimer(false)
            user.setSnapbar(true,'error',`${e.response.data.message}`)
        }

    };

    return (
        <body style={{backgroundImage:`url(${bglogin})`,backgroundSize:'100% 100%',backgroundRepeat: 'no-repeat',position:'fixed',width:'100%',height:'100%'}}>
        {timer ? <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box> : false}
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" sx={{background:'#eeeeee',borderRadius:'22px'}}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {!isLogin ? "Регистрация" : "Авторизация"}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {!isLogin ? "Регистрация" : "Войти"}
                        </Button>
                        <Grid container>
                            <Grid item>
                                <Link onClick={()=>navigate(isLogin ? REGISTRATION_ROUTE : LOGIN_ROUTE)} variant="body2">
                                    {!isLogin ? "Авторизация" : "Зарегистрироваться"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
        </body>
    );
})
export default Auth