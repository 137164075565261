import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SendIcon from "@mui/icons-material/Send";
import Moment from "moment";
import {useEffect, useState} from "react";


const MediaCard = ({rows,updateData,updateData2}) => {
	const [date,setDate] = React.useState('')

	useEffect(()=>{
		const dat = new Date(rows.updatedAt);
		Moment.lang('ru');
		setDate(Moment(dat).format('YYYY-MM-DD HH:mm:ss'));
	},[])
    return (
        <Card sx={{ maxWidth: "100%" }}>
            <CardMedia
                component="img"
                height="140"
                image={require(`../assets/${rows.image}`)}
                alt="green iguana"
                sx={{objectFit:'fill'}}
            />

            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {rows.marketplaceType}
                </Typography>
<Typography variant="body2" color="text.secondary">
    {rows.name}
</Typography>
<Typography variant="body2" color="text.secondary">
    Обновлен {date}
</Typography>
</CardContent>
<CardActions sx={{float:'right'}}>
    <div>
    <Button size="small" onClick={() => updateData2(true, rows.id)}>Загрузить товары</Button>
    <Button size="small" onClick={() => updateData(true, rows.id)} endIcon={<SendIcon />}>Изменить цены</Button>
    </div>
</CardActions>
</Card>
);
}

export default MediaCard