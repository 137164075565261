import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {addStore} from "../http/storeAPI";
import {Context} from "../index";
import {observer} from "mobx-react-lite";

const DialogAddStore = observer(() => {
    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState('')
    const {user} = React.useContext(Context)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAdd = async () => {
        await addStore(text).then()
        setOpen(false);
        user.setAddStore(true)
        user.setSnapbar(true,'success','Магазин успешно добавлен!')
        user.setAddStore(false)
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen} sx={{marginLeft:1,height:'50px'}}>
                Добавить магазин
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Добавить магазин</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Внимание! После добавления магазина удалить его из системы можно будет только через службу поддержки. Будте внимательны.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Название"
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={(e) =>
                            setText(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button onClick={handleAdd}>Создать</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
})

export default DialogAddStore