import React, {PureComponent, useEffect, useState} from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {observer} from "mobx-react-lite";
import {getMarketplaceGroupByModel, getUpdateMarketplace} from "../http/userAPI";
import {Spinner} from "react-bootstrap";

const MixBarChart = observer(() => {
    const [res,setRez] = React.useState()
    useEffect(()=>{
       getMarketplaceGroupByModel(222).then(data => setRez(data))
    },[])
    console.log(res)

        return (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={res}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="value" stackId="a" fill="#1576e6" />
                </BarChart>
            </ResponsiveContainer>
        );

})

export default MixBarChart