import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import banner1 from  '../assets/back.gif'
import user from "../assets/user.jpg";
export default function MediaControlCard() {


    return (
        <Card sx={{ display: 'flex',background:'show',justifyContent:'space-between',marginBottom:'22px'}}>
            <Box sx={{ display: 'flex', flexDirection: 'column',minHeight:'200px'}}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                
                    <Typography component="div" variant="h5" sx={{marginBottom:'10px'}}>
                        Делаем аналитику проще
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        Запущен PIM сервис для изменения цен на Ozon, Wildberries, YandexDBS, SberMegaMarket
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        Запущена Аналитика (режим тестирования)
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        Запуск системы контроля пользователей - 10 апреля
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        Запуск системы управления балансом - 10 апреля
                    </Typography>
                </CardContent>
            </Box>
            <img src={banner1} alt="webscript" style={{width:'478px',height:'70%'}} />
        </Card>
    );
}