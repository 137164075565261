import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import  ImgTab from  '../assets/ozon.jpg'
import Avatar from "@mui/material/Avatar";
import {useEffect} from "react";
import {getAllProductsPim, updateProductPimChecked, updateProductPricePim} from "../http/marketAPI";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {Checkbox} from "@mui/material";


const ControlledCheckbox = (params) => {
    const [checked, setChecked] = React.useState(false);

    useEffect(() => {
       setChecked(params.row.checked)
    },[])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateProductPimChecked(params.row.id,event.target.checked).then()

        setChecked(event.target.checked);
    };

    return (
        <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
}

const BasicEditingGrid = observer(({rows,marketId}) => {

    const {user} = React.useContext(Context)
    const [rowsIn, setRowsIn] = React.useState([])

    useEffect(()=>{
        setRowsIn(rows)
        if(rows?.[0]?.['marketplaceType'] === 'YandexDBS'){
            user.setSnapbar(true, 'warning', 'Для обновления цен, новая цена должна быть минимум на 5% меньше чем старая!')
        }
    },[rows])

    const processRowUpdate = async (params) => {
        console.log(params)
        await updateProductPricePim(params['id'], params['price'],params['oldPrice'],params['dateFrom'],params['dateTo']).then( )
        await getAllProductsPim(marketId).then(data => setRowsIn(data))
        user.setSnapbar(true, 'success', 'Цена в очереди на обновление!')
    }


    return (
        <div style={{ height: '100%', width: '100%',background:'white'}}>
            <DataGrid
                rowHeight={125}
                rows={rowsIn}
                columns={rowsIn?.[0]?.['marketplaceType'] === 'Sbermarket' ? columns :
                    rowsIn?.[0]?.['marketplaceType'] === 'Wildberries' ? columns2 : columns3
                }
                processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
            />
        </div>
    );
})

const columns = [
    {
        field: 'image',
        headerName: 'Изображение',
        width: 200,
        renderCell: (params) => {
            return (
                <>
                    <Avatar src={params.value.avatar} variant="square" sx={{width:'100%',height:'100%'}}/>
                </>
            );
        }
    },
    { field: 'name', headerName: 'Товар', width: 300},
    { field: 'category', headerName: 'Категория',width: 220},
    { field: 'price', headerName: 'Цена',width: 100,editable: true},
    {
        field: 'check',
        headerName: 'Старая цена из фида',
        width: 200,
        renderCell: ControlledCheckbox
    },
    {
        field: 'dateFrom',
        headerName: 'Цена от',
        type: 'dateTime',
        width: 120,
        editable: true,
    },
    {
        field: 'dateTo',
        headerName: 'Цена до',
        type: 'dateTime',
        width: 120,
        editable: true,
    },
];

const columns2 = [
    {
        field: 'image',
        headerName: 'Изображение',
        width: 200,
        renderCell: (params) => {
            return (
                <>
                    <Avatar src={params.value.avatar} variant="square" sx={{width:'100%',height:'100%'}}/>
                </>
            );
        }
    },
    { field: 'name', headerName: 'Товар', width: 300},
    { field: 'category', headerName: 'Категория',width: 220},
    { field: 'brand', headerName: 'Бренд',width: 100},
    { field: 'price', headerName: 'Цена',width: 100,editable: true},
    { field: 'oldPrice', headerName: 'Зачеркнутая цена',width: 150,editable: true},
    {
        field: 'dateFrom',
        headerName: 'Цена от',
        type: 'dateTime',
        width: 120,
        editable: true,
    },
    {
        field: 'dateTo',
        headerName: 'Цена до',
        type: 'dateTime',
        width: 120,
        editable: true,
    },
];

const columns3 = [
    {
        field: 'image',
        headerName: 'Изображение',
        width: 200,
        renderCell: (params) => {
            return (
                <>
                    <Avatar src={params.value.avatar} variant="square" sx={{width:'100%',height:'100%'}}/>
                </>
            );
        }
    },
    { field: 'name', headerName: 'Товар', width: 500},
    { field: 'price', headerName: 'Цена',width: 100,editable: true},
    { field: 'oldPrice', headerName: 'Зачеркнутая цена',width: 150,editable: true},
    {
        field: 'dateFrom',
        headerName: 'Цена от',
        type: 'dateTime',
        width: 120,
        editable: true,
    },
    {
        field: 'dateTo',
        headerName: 'Цена до',
        type: 'dateTime',
        width: 120,
        editable: true,
    },
];


export default BasicEditingGrid;