import React, {useEffect} from 'react';
import UpdateRowsProp from "../components/TableXMui2";
import TablePim from "../components/TablePim";
import {getAllUserProductsPim} from "../http/marketAPI";

const PimUnite = () => {

    return (
        <div style={{display:'flex'}}>
           <UpdateRowsProp/>

        </div>
    );
};

export default PimUnite;