import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {allMarketplace, getUpdateMarketplace} from "../http/userAPI";
import {observer} from "mobx-react-lite";
import {useEffect} from "react";
import * as React from 'react';
const data = [
    {

        type: 'Обновлен',
        createdAt: '2022-05-02T12:30:30.905Z',
        updatedAt: '2022-05-02T12:30:30.905Z',
        marketplaceId: 199
},

{
    type: 'Обновлен',
    createdAt: '2022-05-05T12:30:30.905Z',
    updatedAt: '2022-05-05T12:30:30.905Z',
    marketplaceId: 299
    },
    {
        type: 'Обновлен',
        createdAt: '2022-05-06T12:30:30.905Z',
        updatedAt: '2022-05-07T12:30:30.905Z',
        marketplaceId: 499
    },
];

const PureComponentZ = observer(() => {


        return (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="type"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Bar dataKey="marketplaceId" stackId="a" fill="#8884d8"/>

                </BarChart>
            </ResponsiveContainer>
        );

})

export default PureComponentZ;
