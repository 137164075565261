import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {useContext, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {allMarketplace} from "../../../http/userAPI";
import {updateCompare} from "../../../http/storeAPI";
import {getCompare} from "../../../http/storeAPI";
import {deleteCompare} from "../../../http/storeAPI";
import {Context} from "../../../index";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const ButtonGroupMarket = observer(({id, marketplaces, dateCompare, type}) => {

  const [alignment, setAlignment] = React.useState('left');
  const [formats, setFormats] = React.useState(() => [1]);
  const [formats2, setFormats2] = React.useState(() => [1]);
  const {user} = useContext(Context)
  const {analytics} = useContext(Context)

  useEffect(()=>{
    allMarketplace(user.storeId.id.toString()).then(data => {
      setFormats2(data)
      console.log(data)})
    setFormats(marketplaces)
  },[])

  const handleFormat = async (event, newFormats) => {
    setFormats(newFormats);
    await updateCompare(id, newFormats, 'Non', 'Non', 'Non').then()
    analytics.setCompareTrigger(newFormats)
  };

  const deleteComp = async () => {
    await deleteCompare(id).then()
    analytics.setCompareTrigger([id])
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <div>
      <Paper
        elevation={0}
        key={id}
        sx={{
          justifyContent:'center',
          display: 'flex',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
        }}
      >
       
        <StyledToggleButtonGroup
          size="small"
          value={formats}
          onChange={handleFormat}
          aria-label="text formatting"
        >
          
         
          {formats2?.data?.map((data)=>(
          <ToggleButton value={data.id} aria-label="underlined">
            {data.marketplaceType}
          </ToggleButton>
          ))}
          <div style={{marginTop:'5px'}}>
          <IconButton aria-label="delete" onClick={deleteComp}>
            <DeleteIcon />
          </IconButton>
          </div>
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
})

export default ButtonGroupMarket
