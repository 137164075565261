import React from 'react';
import NestedGridPim from "../components/GridTablePim";
import TablePim from "../components/TablePim";


const PimSettings = () => {
    return (
        <div>
            <NestedGridPim/>
        </div>
    );
};

export default PimSettings;