import React from 'react';
import MediaCard from "../components/MediaCard";
import MediaControlCard from "../components/MediaControlCard";

const Admin = () => {
    return (
        <div>
            <MediaCard/>,
            <MediaControlCard/>
        </div>
    );
};

export default Admin;