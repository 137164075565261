import React, {useEffect, useState} from 'react';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import BasicEditingGrid from "./TableXMui";
import {getAllProductsPim, updateProductStatusPrice} from "../http/marketAPI";
import SendIcon from '@mui/icons-material/Send';
import Button from "@mui/material/Button";
import {Context} from "../index";

const DriwerMenuPim = ({openState,marketplaceId,updateData}) => {

    const [open,setOpen] = React.useState(false)
    const [products, setProducts] = useState({data:[]})
    const {user} = React.useContext(Context)
    useEffect(()=>{
        getAllProductsPim(marketplaceId).then(data => setProducts(data))
       setOpen(openState)
    },[])


    const sleep = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const closeHandle = async () => {
        await sleep(500);
        updateData(false,marketplaceId)
    }

    const handleAdd = async () => {
        updateProductStatusPrice(marketplaceId).then()
        user.setSnapbar(true,'success','Задание поставлено в очередь')
    }

    const list = () => {
        return(
        <Box
            sx={{ width: '100%', height: '95%', background:'linear-gradient(21deg, #dd03e4, #5611ec)'}}
            role="presentation"

        >
            <Button variant="contained" sx={{width:'100%'}} onClick={handleAdd} endIcon={<SendIcon />}>
                Поставить задание на обновление цен
            </Button>
            <BasicEditingGrid rows={products} marketId={marketplaceId}/>
        </Box>)
    }
    return (
            <div>
                <React.Fragment>
                    <SwipeableDrawer
                        PaperProps={{
                            sx: {
                                width: '80%'
                            }
                        }}
                        anchor={'right'}
                        open={open}
                        onClose={() => {
                            setOpen(false)
                            closeHandle().then()
                        }}
                        onOpen={() => setOpen(true)}
                    >
                        {list()}

                    </SwipeableDrawer>
                </React.Fragment>

            </div>
    );
};

export default DriwerMenuPim;