import * as React from 'react';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import {useContext, useEffect, useState} from "react";
import {getUpdateMarketplace, marketplaceContent} from "../http/userAPI";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import {GridToolbar,GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExport} from '@mui/x-data-grid';
const columns = [
    {
        field: 'image',
        headerName: 'Изображение',
        width: 200,
        renderCell: (params) => {
            return (
                <>
                    <Avatar src={params.value} variant="square" sx={{width:'100%',height:'100%'}}/>
                </>
            );
        }
    },
    { field: 'id', headerName: 'ID', width: 'max-content', flex:1},
    { field: 'dateSale', headerName: 'Дата продажи', width:'max-content' , flex:1},
    { field: 'supplierArticle', headerName: 'Артикул из маркетплейса',width:'max-content', flex:3, editable:true },
    {
        field: 'quantity',
        headerName: 'Колличество в заказе',
        type: 'number', width:'max-content', flex:1
    },
    { field: 'totalPrice', headerName: 'Сумма',  width:'max-content' , flex:1},
];





const DataTable = observer(({MarketID,Column}) => {

    const localizedTextsMap = {
  columnMenuUnsort: "Сбросить фильтр",
  columnMenuSortAsc: "Сортировать по убыванию",
  columnMenuSortDesc: "Сортировать по нарастанию",
  columnMenuFilter: "Фильтр",
  columnMenuHideColumn: "Скрыть столбцы",
  columnMenuShowColumns: "Показывать столбцы"
};

    function CustomToolbar() {
    return (
    <GridToolbarContainer sx={{float:'right', marginTop:'-37px'}}>
      <GridToolbarExport csvOptions={{
    delimiter: ';',
    utf8WithBom: true,
  }}/>
    </GridToolbarContainer>
  );
}

    const [data, setdata] = useState({data:[]})
    const {analytics,user} = useContext(Context)

    useEffect(()=>{
       
        marketplaceContent(MarketID).then(data => setdata(data))

    },[analytics.analyticMenuDataMarketplaces,user.storeId.id])

    useEffect(()=>{
        console.log(data)
    },[data])

    return (
        <div style={{ height: 1000, width: '100%', background:'white'}}>
            <DataGrid
                rows={data}
                rowHeight={110}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[6]}
                checkboxSelection
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                components={{
                    Toolbar: CustomToolbar 
                }}
                componentsProps={{ toolbar: { csvOptions: { delimiter: ';', utf8WithBom: true } } }}
            />
        </div>
    );
})

DataTable.propTypes = {
    MarketID: PropTypes.number,
    Column: PropTypes.number
    .isRequired
}

export default DataTable