import React, {PureComponent, useEffect} from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer,Legend,LabelList } from 'recharts';
import {observer} from "mobx-react-lite";
import {getMarketplaceGroupByDateOrSumm} from "../http/userAPI";
import { toJS } from 'mobx'
import {Context} from "../index";



const data = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
];

const COLORS = ['blue', 'yellow', 'green', 'b2aaf1'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return `${(percent * 100).toFixed(0)}%`

};



const PieChartOne = observer(({MarketID}) => {

    const [res,setRez] = React.useState({summ:[],val:[]})
    const {analytics,user} = React.useContext(Context)

    const renderCustomizedLabel2 = ({value}) => {


  let data = `${value}шт.`

  if(analytics.analyticMenuDataPie === '2'){
    data = `${value}Р`
  }
 
  return `${data}`

};

    useEffect(()=>{
            console.log(res)
    },[res])
    useEffect(()=>{
        getMarketplaceGroupByDateOrSumm(user.storeId.id.toString(), toJS(analytics.analyticMenuDataMarketplaces), analytics.analyticMenuDataFrom, analytics.analyticMenuDataTo, analytics.analyticMenuDataInterval).then(data => setRez(data))
    },[analytics.analyticMenuDataMarketplaces,analytics.analyticMenuDataInterval])
        console.log(res)
        return (
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={500} height={500}>
                    <Pie
                        data={analytics.analyticMenuDataPie === '2' ? res['summ'] : res['val']}
                        cx="50%"
                        cy="50%"
                        labelLine
                        outerRadius={110}
                        fill="#8884d8"
                        dataKey="value"
                        label ={renderCustomizedLabel2}
                    >
    				
                        {res['summ'].map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry['color']} />
                        ))}
                        <LabelList
         valueAccessor={renderCustomizedLabel}
         fill="white"
      />
                    </Pie>
                     <Legend/>
                </PieChart>
            </ResponsiveContainer>
        );

})

export default PieChartOne