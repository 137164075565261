import * as React from 'react';
import {DataGrid, useGridApiContext} from '@mui/x-data-grid';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from "@mui/material/Avatar";
import {Autocomplete, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {
    deleteUnite, deleteUniteColumn, getAllProductByMarketId, getAllProductsPim,
    getAllUnite,
    getAllUniteColumn, getAllUserProductsPim,
    updateProductPricePim,
    updateUnite,
    updateUnite2
} from "../http/marketAPI";
import {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import PropTypes from "prop-types";
import {useCellEditing} from "@mui/x-data-grid/hooks/features/editRows/useGridCellEditing.old";
import {CircularProgress, LinearProgress} from "@mui/material";



const SelectEditInputCell = (props) => {

    const { id, value, field } = props;
    const apiRef = useGridApiContext();
    const [product,setProduct] = React.useState([])
    const [value2,setValue2]= React.useState('')
    const [rows,setRows] = React.useState([])
    const {user} = React.useContext(Context)

    useEffect(()=>{
        setValue2(value)
        getAllProductByMarketId(field,user.storeId.id.toString()).then(data => setProduct(data))
        getAllUnite(user.storeId.id.toString()).then(data => setRows(data['data']))
    },[])



    const handleChange = async (event,newValue) => {
        await apiRef.current.setEditCellValue({ id, field, value: newValue['label'] });
        apiRef.current.stopCellEditMode({ id, field });
        setValue2(event.target.value)
        const buf = await Object.assign([], rows);
        const index = await buf.findIndex(el => Number(el.id) === Number(id));
        const bufObj = buf[index]
        const bufValue = {productId:newValue['id']}
        delete bufObj[field]
        if(field==='product'){
            bufObj[field+'id'] = newValue['id']
            bufObj[field] = newValue['label']
        }else{
            bufObj[field] = newValue['label']
            bufObj[field+'idSpec'] = bufValue
        }

        console.log(bufObj)
        buf.splice(index,1,bufObj)
        await updateUnite(user.storeId.id.toString(),'name',buf).then()
        setRows(buf)
    };

    return (
        <Autocomplete
            disablePortal
            onChange={(event, newValue) => {
                handleChange(event,newValue)
            }}
            value={value2}
            id="combo-box-demo"
            options={product}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Товар" />}
        />
    );
};



SelectEditInputCell.propTypes = {
    /**
     * The column field of the cell that triggered the event.
     */
    field: PropTypes.string.isRequired,
    /**
     * The grid row id.
     */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    /**
     * The cell value.
     * If the column has `valueGetter`, use `params.row` to directly access the fields.
     */
    value: PropTypes.any,
};

const renderSelectEditInputCell = (params) => {
    return <SelectEditInputCell {...params} />;
};



const UpdateRowsProp = observer(() => {

    const {user} = React.useContext(Context)

    const [rows, setRows] = React.useState([]);
    const [deleteRow, setDeleteRow] = React.useState(0);
    const [columns,setColumns] = React.useState([])
    const [timer,setTimer] = React.useState(true)
    useEffect( async () => {
    	setTimer(true)
        await getAllUniteColumn(user.storeId.id.toString()).then(data => {
            let arr = []
            for (const val of data) {
                val.renderEditCell = renderSelectEditInputCell
                arr.push(val)
            }
            setColumns(arr)
        })
        await getAllUnite(user.storeId.id.toString()).then(data => setRows(data?.['data'])).finally(data2 => {
                        setTimeout(2000)
                        setTimer(false)
                    })
        user.setSnapbar(true, 'warning', 'Не допускайте дубликатов товаров при выборе, даныые будут обрезаны!')
    },[user.storeId.name])

    useEffect(()=>{
        console.log(rows)
    },[rows])


    const handleDeleteRow = async () => {
        const buf = await Object.assign([], rows);
        buf.splice(deleteRow,1)
        await deleteUnite(buf,user.storeId.id.toString()).then()
        setRows(buf)
        user.setSnapbar(true, 'success', 'Строка удалена!')
    };

    const handleDeleteUniteIdInProduct = async () => {
        await deleteUniteColumn(user.storeId.id.toString()).then()
        user.setSnapbar(true, 'success', 'Внимание! Текущие связи удалины и будут обновлены в скоро времени')
    }

    const handleCellFocus =  React.useCallback((event) => {
        const row = event.currentTarget.parentElement;
        const id = row.dataset.rowindex;
        console.log(id)
        setDeleteRow(id)
    }, []);

    const handleAddRow = async () => {
        await getAllUnite(user.storeId.id.toString()).then(async data => {
            
            let arr
        const arr2 = await Object.assign([], data['data']);
        if(arr2.length > 0){
        const max = await data['data'].reduce((acc, curr) => Number(acc.id) > Number(curr.id) ? acc : curr);
        const buf = Number(max['id']) + 1
        const bufObj = await Object.assign({},max)
        bufObj['id'] = String(buf)
        arr = await Object.assign([], data['data']);
        await arr.push(bufObj)
        } else {
            arr = await Object.assign([], [{id: 1, product: "product"}]);
        }
        await updateUnite(user.storeId.id.toString(), 'name',arr).then()
        setRows(arr)
        })
        
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stack direction="row" spacing={1}>
                <Button size="small" onClick={handleAddRow}>
                    Добавить
                </Button>
                <Button size="small" onClick={handleDeleteRow}>
                    Удалить
                </Button>
                <Button size="small" onClick={handleDeleteUniteIdInProduct} sx={{color:'#d21919'}}>
                    Очистить связи
                </Button>
            </Stack>
            <Box sx={{ height: 600, mt: 1,background:'white'}}>
                {timer ? (<Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>): 
                <DataGrid rows={rows} columns={columns} rowHeight={31}
                          componentsProps={{
                              cell: {
                                  onFocus: handleCellFocus,
                              },
                          }}
                          experimentalFeatures={{ newEditingApi: true }}/>
                      }
            </Box>
        </Box>
    );
})

export default UpdateRowsProp;
