import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import {useEffect, useState} from "react";


const ToggleButtonBlockPie = observer(() => {

  const analytics = React.useContext(Context)

  const handleChange = (event, newAlignment) => {
    analytics.analytics.setAnalyticMenuDataPie(newAlignment);
  };

  useEffect(()=>{
      console.log(analytics.analytics.analyticMenuDataPie)
  },[])

  return (
    <ToggleButtonGroup
      sx={{width:'100%',position:'absolute',zIndex:1}}
      color="primary"
      value={analytics.analytics.analyticMenuDataPie}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton value="1" sx={{width:'50%'}}>Колличество</ToggleButton>
      <ToggleButton value="2" sx={{width:'50%'}}>Сумма</ToggleButton>
    </ToggleButtonGroup>
  );
})

export default ToggleButtonBlockPie
