import * as React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import {useContext, useEffect, useState} from "react";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import AddShoppingCartIcon from '@mui/icons-material/AddBox';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from "@mui/material/Typography";
import {Autocomplete, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {getModified, getModifiedById, updateModifiedById, updateStateGrid} from "../http/marketAPI";
import DeleteIcon from '@mui/icons-material/Delete';


const SwipeableTemporaryDrawer = observer(() => {
    const {user} = useContext(Context)
    const [autocompleteArrayChange, setAutocompleteArrayChange] = useState({condition:[{condition_data:'',operator:'',variable:''}]});
    const [autocompleteArrayChange2, setAutocompleteArrayChange2] = useState({action:[{action_type:'',action_data:''}]});
    const [arrayIndexText,setArrayIndexText] = useState({text:[{field:''}]})
    const [arrayIndexText2,setArrayIndexText2] = useState({text:[{field:''}]})
    const [gridData, setGridData] = useState({data:[]});
    const [cellData, setCellData] = useState([1,2]);
    const [arrayGridData, setArrayGridData] = useState([
        [null, null, null],
        [null, null, null],
        [null, null, null]
    ]);

    useEffect( () => {
        getModifiedById(user.inactiveShemaId).then(data => setGridData(data))
    },[user.inactiveShemaId])

    useEffect( () => {
        getModifiedById(user.inactiveShemaId).then(data => setGridData(data))
        let arrayGridDataFirst = (JSON.parse(JSON.stringify(gridData.data)))
        if(arrayGridDataFirst?.[0]?.[1]){
        setArrayGridData(arrayGridDataFirst)}

        function deepSearch(array, itemz) {
            let buf = []
            array.forEach(function(item, i) {
                item.forEach(function(item2, b) {
                    if(item2['name'] === itemz){
                        buf.push(i,b)
                    }
                });
            });
            return buf
        }
        let cellArray = deepSearch(arrayGridDataFirst,user.inactiveShemaName)
        setCellData(cellArray)
    },[user.inactiveShemaName])


     useEffect( () => {
         setAutocompleteArrayChange(arrayGridData?.[cellData[0]]?.[cellData[1]]?.['data'])
         setAutocompleteArrayChange2(arrayGridData?.[cellData[0]]?.[cellData[1]]?.['data'])
         console.log(arrayGridData)
     },[arrayGridData])


    const handleAdd = () => {
        const bufObj = {condition_data: '', operator: '', variable: ''}
        const bufData = autocompleteArrayChange
        const bufData2 = Object.assign([], arrayGridData);
        bufData['condition'].splice(bufData['condition'].length, 0, bufObj)
        bufData2[cellData[0]][cellData[1]]['data']['condition'] = bufData['condition']
        setArrayGridData(bufData2);
        setAutocompleteArrayChange(bufData)
    };

    const handleDelete = (e,index) => {
        const bufObj = {condition_data: '', operator: '', variable: ''}
        const bufData = autocompleteArrayChange
        const bufData3 = Object.assign([], arrayGridData);
        if(bufData['condition'].length === 1){
        bufData['condition'].splice(index, 1,bufObj)
        }else {
            bufData['condition'].splice(index, 1)
        }
        bufData3[cellData[0]][cellData[1]]['data']['condition'] = bufData['condition']
        setArrayGridData(bufData3);
        setAutocompleteArrayChange(bufData)
        console.log(arrayGridData)
    };

    const handleAutocomplete = (newValue,index) => {
            const operator = autocompleteArrayChange['condition'][index]['operator']
            const variable = autocompleteArrayChange['condition'][index]['variable']
            const bufObj = {condition_data: newValue, operator: operator, variable: variable}
            const bufData = autocompleteArrayChange
            bufData['condition'].splice(index, 1, bufObj)
            setAutocompleteArrayChange(bufData)
    };

    const handleAutocomplete2 = (newValue,index) => {
        const condition_data = autocompleteArrayChange['condition'][index]['condition_data']
        const variable = autocompleteArrayChange['condition'][index]['variable']
        const bufObj = {condition_data: condition_data, operator: newValue, variable: variable}
        const bufData = autocompleteArrayChange
        bufData['condition'].splice(index, 1, bufObj)
        setAutocompleteArrayChange(bufData)
        console.log(bufData)
    };

    const handleAutocomplete3 = (newValue) => {
        const action_data = autocompleteArrayChange['action'][0]['action_data']
        const bufObj = {action_type: newValue, action_data:action_data}
        const bufData = autocompleteArrayChange2
        bufData['action'].splice(0, 1, bufObj)
        setAutocompleteArrayChange2(bufData)
        console.log(bufData)
    };

    const handleChangeText = (e,index) => {
        const bufData = arrayIndexText
        const condition_data = autocompleteArrayChange['condition'][index]['condition_data']
        const operator = autocompleteArrayChange['condition'][index]['operator']
        bufData['text'].splice(index, 1, e.target.value)
        const bufObj = {condition_data: condition_data, operator: operator, variable: bufData['text'][index]}
        const bufData2 = autocompleteArrayChange
        bufData2['condition'].splice(index, 1, bufObj)
        setAutocompleteArrayChange(bufData2)
    }

    const handleChangeText2 = (e) => {
        const bufData = arrayIndexText2
        const action_type = autocompleteArrayChange2['action'][0]['action_type']
        bufData['text'].splice(0, 1, e.target.value)
        const bufObj = {action_type: action_type, action_data: e.target.value}
        const bufData2 = autocompleteArrayChange2
        bufData2['action'].splice(0, 1, bufObj)
        setAutocompleteArrayChange2(bufData2)
        console.log(bufData2)
    }

    const handleSave = async () => {
        let arrayGridDataFirst = (JSON.parse(JSON.stringify(gridData.stateGrid)))
        const bufDataSave = arrayGridData
        const bufDataSave2 = autocompleteArrayChange
        const bufDataSave3 = autocompleteArrayChange2
        bufDataSave[cellData[0]][cellData[1]]['data']['condition'] = bufDataSave2['condition']
        bufDataSave[cellData[0]][cellData[1]]['data']['action'] = bufDataSave3['action']
        console.log(bufDataSave)
        await updateModifiedById(user.inactiveShemaId, bufDataSave).then(data => setArrayGridData(data['data']))
        function deepSearch(array, itemz) {
            let buf = []
            array.forEach(function(item, i) {
                    if(item['name'] === itemz){
                        buf.push(i)
                    }
            });
            return buf
        }
        let cellArray = deepSearch(arrayGridDataFirst,user.inactiveShemaName)
        let arrayCorrect = arrayGridDataFirst
        arrayCorrect[cellArray[0]]['color'] = 'red'
        user.setTriggerButtonStateShema(true)
        await updateStateGrid(user.inactiveShemaId,arrayCorrect)
        user.setTriggerButtonStateShema(false)
        user.setSnapbar(true,'success','Ячейка успешно сохранена')
    };



    const conditionData1 = [
        'ID',
        'Дата продажи',
        'Артикул из маркетплейса',
        'Штрихкод',
        'Колличество',
        "Сумма"]

    const list = () => (

        <Box
            sx={{ width: 660 }}
            role="presentation"

        >
            <Typography variant="h6" sx={{marginTop:'22px'}} >
                Конструктор ячейки {user.inactiveShemaName}
            </Typography>

            <div className='blockPassword'>
                <Typography sx={{marginTop:'22px'}} >
                    Условие If (если)
                    <IconButton color="primary" onClick={handleAdd} aria-label="add to shopping cart" sx={{justifyContent:'end'}}>
                        <AddShoppingCartIcon />
                    </IconButton>
                </Typography>
                {arrayGridData?.[cellData[0]]?.[cellData[1]]?.['data']?.['condition'].map((num,index)=>(
                <div className='blockPassword cart' key={index+'block'+ num.condition_data} style={{width:'100%',height:'100%'}}>

            <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                    handleAutocomplete(newValue,index)
                }}
                id="combo-box-demo"
                key={index+'Auth'}
                options={conditionData1}
                defaultValue={arrayGridData?.[cellData[0]]?.[cellData[1]]?.['data']?.['condition']?.[index]?.['condition_data']}
                sx={{ width: 217, justifyContent:'right'}}
                renderInput={(params) => <TextField {...params} label="Столбец" />}
            />

                    <Autocomplete
                        disablePortal
                        onChange={(event, newValue) => {
                            handleAutocomplete2(newValue,index)
                        }}
                        key={index+'Auth0'}
                        id="combo-box-demo2"
                        options={['==','!=']}
                        defaultValue={arrayGridData?.[cellData[0]]?.[cellData[1]]?.['data']?.['condition']?.[index]?.['operator']}
                        sx={{ width: '110px', justifyContent:'right'}}
                        renderInput={(params) => <TextField {...params} label="Оператор" />}
                    />


                    <TextField id="outlined-basic" key={index+'text'} onChange={(e) =>
                       handleChangeText(e,index)} label="Содержит" variant="outlined" defaultValue={
                        arrayGridData?.[cellData[0]]?.[cellData[1]]?.['data']?.['condition']?.[index]?.['variable']
                    } sx={{width:217}}/>
                    <Button variant="outlined" key={index} onClick={(e) => handleDelete(e,index)} startIcon={<DeleteIcon />} sx={{width:109,color:'red'}}>
                        Удалить
                    </Button>
                </div>
                ))}
                <Typography sx={{marginTop:'22px'}} >
                    Действие (то)

                </Typography>

                <div className='blockPassword cart' style={{width:'100%',height:'100%',marginTop:'10px'}}>

                    <Autocomplete
                        disablePortal
                        onChange={(event, newValue) => {
                            handleAutocomplete3(newValue)
                        }}
                        id="combo-box-demo3"
                        options={['Сложить все', 'Вывести']}
                        defaultValue={arrayGridData?.[cellData[0]]?.[cellData[1]]?.['data']?.['action']?.[0]?.['action_type']}
                        sx={{ width: '290px', justifyContent:'right'}}
                        renderInput={(params) => <TextField {...params} label="Действие" />}
                    />
                    <TextField id="outlined-basic" onChange={(e) =>
                        handleChangeText2(e)} label="Аргумент" variant="outlined" defaultValue={
                        arrayGridData?.[cellData[0]]?.[cellData[1]]?.['data']?.['action']?.[0]?.['action_data']} sx={{width:363}}/>
                </div>
            </div>
            <Button variant="contained" onClick={handleSave} sx={{float:'right'}}>Сохранить</Button>
        </Box>
    );

    return (
        <div>
            <React.Fragment key={33}>
                <SwipeableDrawer
                    anchor={'right'}
                    open={user.toggleDriver}
                    onClose={() => user.setToggleDriver(false)}
                    onOpen={() => user.setToggleDriver(true)}
                >
                    {list()}
                </SwipeableDrawer>
            </React.Fragment>

        </div>
    );
})

export default SwipeableTemporaryDrawer
