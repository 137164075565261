import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {observer} from "mobx-react-lite";
import {useContext} from "react";
import {Context} from "../index";
import {marketplaceData} from "../http/userAPI";
import {createUpdateNotify, WildberriesAuthState} from "../http/marketAPI";
import {CircularProgress, LinearProgress} from "@mui/material";
import ColorToggleButton from "./ToggleButton";

const FormDialog = observer(() => {
    const [open, setOpen] = React.useState(false);
    const [marketplace, setMarketplace] = React.useState('');
    const [textField, setTextField] = React.useState('Not');
    const [textName, setTextName] = React.useState('Not');
    const [textLkNum, setTextLkNum] = React.useState('Not');
    const [textTokenAuth, setTextTokenAuth] = React.useState('Not');
    const [textUrlFid, setTextUrlFid] = React.useState('Not');
    const [buttonState, setButtonState] = React.useState(false)
    const {user} = useContext(Context)
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAdd = async () => {

        async function notify(marketplaceId, type, name, marketName) {
            await createUpdateNotify(marketplaceId, type, name, marketName).then()
        }

        if(user.storeId.name === ''){
            user.setSnapbar(true, 'error', 'Сначала нужно выбрать магазин!')
            return setOpen(false)
        }

        if(marketplace === ''){
            user.setSnapbar(true, 'error', 'Выберите маркетплейс и заполните данные!')
            return setOpen(false)
        }

        try {
            if (marketplace === 6) {
                if(textField === 'Not' || textName === 'Not'){
                    return user.setSnapbar(true, 'warning', 'Должны бать заполнены все поля!')
                }
                user.setAdd(true)
                setOpen(false)
                user.setButtonState(true)
                const marketplaceId = await marketplaceData('Wildberries', '', '', '', '', textField, 'wildberries.jpg'
                        , 'https://srv0.ru/api/integration/wb?tokenAuth=A2zi6Mut9', textName,'https://suppliers-api.wildberries.ru/public/api/v1/info','https://suppliers-api.wildberries.ru/content/v1/cards/cursor/list','',user.storeId.id.toString())
                await notify(marketplaceId, 'Поставлен в очередь', textName, "Wildberries")
                user.setButtonState(false)
                user.setAdd(false)
                user.setSnapbar(true, 'success', 'Маркетплейс добавлен и поставлен в очередь на обновление!')
            }
            if (marketplace === 5) {
                if(textLkNum === 'Not' || textField === 'Not' || textName === 'Not'){
                    return user.setSnapbar(true, 'warning', 'Должны бать заполнены все поля!')
                }
                user.setAdd(true)
                setOpen(false)
                user.setButtonState(true)
                const marketplaceId = await marketplaceData('Ozon', '', '', textLkNum, '', textField, 'ozon.jpg'
                    , '', textName,'https://api-seller.ozon.ru/v1/product/import/prices','https://api-seller.ozon.ru/v2/product/list','',user.storeId.id.toString())
                await notify(marketplaceId, 'Поставлен в очередь', textName, "OZON")
                user.setButtonState(false)
                user.setAdd(false)
                user.setSnapbar(true, 'success', 'Маркетплейс добавлен и поставлен в очередь на обновление!')
            }
            if (marketplace === 7) {
                if(textField === 'Not' || textName === 'Not'){
                    return user.setSnapbar(true, 'warning', 'Должны бать заполнены все поля!')
                }
                user.setAdd(true)
                setOpen(false)
                user.setButtonState(true)
                const marketplaceId = await marketplaceData('Sbermarket', '', '', '', '', textField, 'sber.jpg'
                    , '', textName,'https://partner.sbermegamarket.ru/api/merchantIntegration/v1/offerService/manualPrice/save','',textUrlFid,user.storeId.id.toString())
                await notify(marketplaceId, 'Поставлен в очередь', textName, "СберМегаМаркет")
                user.setButtonState(false)
                user.setAdd(false)
                user.setSnapbar(true, 'success', 'Маркетплейс добавлен и поставлен в очередь на обновление!')
            }
            if (marketplace === 1) {
                //в разработке
            }
            if (marketplace === 2) {
                if(textLkNum === 'Not' || textField === 'Not' || textName === 'Not' || textTokenAuth === 'Not'){
                    return user.setSnapbar(true, 'warning', 'Должны бать заполнены все поля!')
                }
                user.setAdd(true)
                setOpen(false)
                user.setButtonState(true)
                const marketplaceId = await marketplaceData('yandexDBS', '', '', textLkNum, textTokenAuth, textField, 'yandex.jpg'
                    , '', textName,'','','',user.storeId.id.toString())
                await notify(marketplaceId, 'Поставлен в очередь', textName, "YandexDBS")
                user.setButtonState(false)
                user.setAdd(false)
                user.setSnapbar(true, 'success', 'Маркетплейс добавлен и поставлен в очередь на обновление!')
            }
        } catch (e) {
            return user.setSnapbar(true, 'error', `${e}`)
        }


    };

    const handleChange = (event) => {
        setMarketplace(event.target.value);

    };

    const handleChangeText = (event) => {
        setTextField(event.target.value);
    };

    const handleChangeTextAuth = (event) => {
        setTextTokenAuth(event.target.value);
    };

    const handleChangeTextFidSber = (event) => {
        setTextUrlFid(event.target.value);
    };

    const handleChangeTextLkNum = (event) => {
        setTextLkNum(event.target.value);
    };

    const handleChangeTextName = (event) => {
        setTextName(event.target.value);
    };

    return (
        <div style={{position: "relative"}}>
            <Button variant="outlined" sx={{height: '50px'}} onClick={handleClickOpen} disabled={user.buttonState}>
                Добавить маркетплейс
            </Button>

            <Box sx={{display: 'flex', marginLeft: '3%'}}>
                {user.buttonState ? <CircularProgress/> : null}
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Добавить маркетплейс</DialogTitle>
                <DialogContent
                    sx={{maxWidth: "441px"}}
                >
                    <DialogContentText>
                        Выберите маркетплейс из списка и введите данные, мы загрузим всю информацию.

                    </DialogContentText>

                    <Box sx={{minWidth: 120, marginTop: "20px"}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Маркетплейс</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={marketplace}
                                label="Мвркетплейс"
                                onChange={handleChange}
                            >
                                <MenuItem value={2}>YandexDBS</MenuItem>
                                <MenuItem value={5}>OZON</MenuItem>
                                <MenuItem value={6}>Wildberries</MenuItem>
                                <MenuItem value={7}>SberMegaMarket</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    {marketplace !== '' ? (
                        <>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Название"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={handleChangeTextName}
                            />
                            {marketplace === 2 || marketplace === 5 ? (
                                <TextField
                                    margin="dense"
                                    id="lkNumber"
                                    label="Номер ЛК"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={handleChangeTextLkNum}
                                />) : null}
                            <TextField
                                margin="dense"
                                id="token"
                                label="Token"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={handleChangeText}
                            />
                            {marketplace === 1 || marketplace === 2 ? (<TextField
                                autoFocus
                                margin="dense"
                                id="tokenAuth"
                                label="TokenAuth"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={handleChangeTextAuth}
                            />) : null}
                            {marketplace === 7 ? (<TextField
                                autoFocus
                                margin="dense"
                                id="urlFidSber"
                                label="Фид для загрузки товаров"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={handleChangeTextFidSber}
                            />) : null}
                        </>
                    ) : null}

                </DialogContent>
                <DialogActions>
                    <div className={'site'} style={{float:'left',flex:'auto'}}>Магазин: {user.storeId.name === '' ? 'Не выбран' : user.storeId.name}</div>
                    <Button onClick={handleClose}>Отменить</Button>
                    <Button onClick={handleAdd}>Добавить</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
})

export default FormDialog
