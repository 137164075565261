import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import TodayIcon from '@mui/icons-material/Today';
import 'dayjs/locale/ru'
import {Context} from "../../../index";
import {useContext,useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {updateCompare} from "../../../http/storeAPI";
import { toJS } from 'mobx'
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';

import IconButton from '@mui/material/IconButton'


const DatePickerMarket = observer(({id, dateCompare, name}) =>{
  const [value, setValue] = React.useState(dayjs());
  const [value2, setValue2] = React.useState(dayjs().subtract(30, 'd'));
  const [text,setText] = React.useState('default=' + id)
  const {user} = useContext(Context)
  useEffect(()=>{
    if(dateCompare !== '01-01-2023'){
      setValue(dayjs(dateCompare))
    }
  },[])
  const {analytics} = React.useContext(Context)

  const handleChange = async (event) => {
    setValue(event)
    await updateCompare(id, 'Non', event, 'Non', 'Non').then()
  }

  const handleSave = async (event) => {
    await updateCompare(id, 'Non', 'Non', 'Non', text).then()
    user.setSnapbar(true, 'success', 'Название линии успешно изменено')
  }

  useEffect(()=>{
    console.log(text)
  },[text])


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru" key={id+'z'}>
      <DemoContainer components={['DatePicker']} sx={{marginBottom:'22px'}}>
        <DatePicker label={'Дата сравнения'} 
        value={value} 
        key={id}
        views={analytics.compareInterval ? ['year'] : ['month', 'year'] } 
        onChange={(newValue) => {
          handleChange(newValue)
        }}
        />
        <TextField id="outlined-basic" label="Название" variant="outlined" defaultValue={name} onChange={(data) => setText(data.target.value)}/>
       <IconButton aria-label="delete" onClick={handleSave}>
            <SaveIcon />
          </IconButton>
      </DemoContainer>
    </LocalizationProvider>
  );
})

export default DatePickerMarket