import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import UserSite from "./site/UserSite";
import AnalyticsSite from "./site/AnalyticsSite";
import HttpsRedirect from 'react-https-redirect';
export const Context = createContext(null)
console.log(process.env.REACT_APP_API_URL)
ReactDOM.render(
        <Context.Provider value={{
            user: new UserSite(),
            analytics: new AnalyticsSite(),
        }}>
        <HttpsRedirect>
    <App />
    </HttpsRedirect>
        </Context.Provider>,
  document.getElementById('root')
);

